import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { filterCarServiceApi } from "../../api/car-service";
import Datatable from "../../component/datatable";
import { ICarService } from "../../interface/car-service";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const CarServiceList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [carServices, setCarServices] = useState<ICarService[]>([]);

  const filterCarService = async () => {
    try {
      const { data } = await filterCarServiceApi({});

      setCarServices(data ?? [])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterCarService();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col"><h4 className="mb-4 mt-2">ทั้งหมด {carServices.length} รายการ</h4></div>

                <div className="col-12 border-bottom mb-3">
                  <div className="row">
                    <div className="col-12">
                      <Datatable
                        data={carServices}
                        columns={[
                          {
                            title: "ชื่อ",
                            data: "name",
                          },
                          {
                            title: "รูปแบบการบริการ",
                            data: "detail",
                          },
                          {
                            title: "สถานะ",
                            data: "status",
                            render: (data: number) => {
                              switch (data) {
                                case 0:
                                  return "<span class='badge badge-danger'>ไม่ใช้งาน</span>"
                                case 1:
                                  return "<span class='badge badge-success'>ใช้งาน</span>"
                                default:
                                  return ""
                              }
                            },
                          },
                        ]}
                        actions={[
                          {
                            button: {
                              callback(data: ICarService) {
                                navigate(`${process.env.PUBLIC_URL}/car-service/edit/${data.id}`)
                              },
                              code: <button className="btn btn-outline-primary btn-sm">แก้ไข</button>
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CarServiceList;
