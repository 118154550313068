import { AxiosResponse } from "axios";
import { IWalletWithPagignation } from "../interface/wallet";
import { http } from "../utils/http";

export const filterWalletsApi = async (payload: {
  status?: string;
  type?: string;
  userId?: string;
  userName?: string;
  begin?: number;
  end?: number;
  page: number;
  limit: number;
}): Promise<AxiosResponse<IWalletWithPagignation>> => {
  return await http.get("/v1/admin/wallets/filter", payload)
};

export const compensationByUserIdApi = async (payload: {
  userId: string;
  amount: number;
  remark?: string;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/wallets/compensation`, payload)
};

export const confirmWalletByIdApi = async (payload: {
  walletId: string;
  action: "approve" | "reject";
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/wallets/confirm`, payload)
};

export const deductByUserIdApi = async (payload: {
  userId: string;
  amount: number;
  remark?: string;
}): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/wallets/deduct`, payload)
};
