import { Fragment, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { PageLoading } from './loader';

import Sidebar from '../component/sidebar';
import Topbar from '../component/topbar';
import Dashboard from '../page/dashboard/dashboard';
import Driver from '../page/driver';
import DriverEdit from '../page/driver/driver-edit';
import DriverList from '../page/driver/driver-list';
import News from '../page/news';
import NewsForm from '../page/news/news-form';
import NewsList from '../page/news/news-list';
import User from '../page/user';
import UserAdd from '../page/user/user-add';
import UserEdit from '../page/user/user-edit';
import UserList from '../page/user/user-list';
import CarService from '../page/car-service';
import CarServiceList from '../page/car-service/car-service-list';
import CarServiceForm from '../page/car-service/car-service-form';
import Order from '../page/order';
import OrderList from '../page/order/order-list';
import OrderDetail from '../page/order/order-detail';
import Review from '../page/review';
import ReviewList from '../page/review/review-list';
import Appeal from '../page/appeal';
import AppealList from '../page/appeal/appeal-list';
import Report from '../page/report';
import ReportStats from '../page/report/report-stats';
import ReportCarsPerDay from '../page/report/report-cars-per-day';
import ReportTripsPerDay from '../page/report/report-trips-per-day';
import ReportTripsPerCarPerDay from '../page/report/report-trips-per-car-per-day';
import ReportRunningTimePerCarPerDay from '../page/report/report-running-time-per-car-per-day';
import ReportSatisfaction from '../page/report/report-satisfaction';
import ReportAccident from '../page/report/report-accident';
import ReportMistake from '../page/report/report-mistake';
import Credit from '../page/credit';
import DriverReviewList from '../page/driver/driver-review-list';
import UserHistory from '../page/user/user-history';
import DriverDetail from '../page/driver/driver-detail';
import {
  DriverTabProfile,
  DriverTabServiceHistory,
  DriverTabDepositHistory,
  DriverTabWithdrawHistory,
} from '../page/driver/driver-detail-tab';
import CreditRequestList from '../page/credit/credit-request-list';
import CreditHistoryList from '../page/credit/credit-history-list';
import Withdraw from '../page/widthdraw';
import WithdrawRequestList from '../page/widthdraw/withdraw-request-list';
import WithdrawHistoryList from '../page/widthdraw/withdraw-history-list';
import SettingInformation from '../page/setting/setting-information';
import SettingAdmin from '../page/setting/setting-admin';
import RenderNews from '../page/news/news';
import Sending from '../page/sending';
import SaleBill from '../page/sale-bill';
import Setting from '../page/setting';
import {
  SendingTabOnline,
  SendingTabTookOrder,
  SendingTabTookPassenger,
} from '../page/sending/sending-tab';
import { selectAuth } from '../redux/authentication.reducer';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import Page404 from './404';
import { getToken, onMessageListener } from '../firebase';
import {
  getBadgeNewDepositApi,
  getBadgeNewDriverApi,
  getBadgeNewOrderApi,
  getBadgeNewWithdrawApi,
  subsctibeToTopicApi,
} from '../api/core';
import { setBadge } from '../redux/app.reducer';
import SettingUser from '../page/setting/setting-user';
import PromotionCode from '../page/promotion-code';
import PromotionCodeList from '../page/promotion-code/promotion-code-list';
import PromotionCodeForm from '../page/promotion-code/promotion-code-form';
import WithdrawProcess from '../page/widthdraw/withdraw-process';

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const { rules } = useAppSelector(selectAuth)

  const initFcm = async () => {
    try {
      const fcmToken = await getToken();
      if (!!fcmToken) {
        localStorage.setItem("fcm_token", fcmToken);
        await subsctibeToTopicApi(fcmToken);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBadgeNewDriver = async () => {
    try {
      const { data } = await getBadgeNewDriverApi();
      dispatch(setBadge({ type: "newDriver", total: data.total }))
    } catch (error) {
      throw error;
    }
  }

  const getBadgeNewOrder = async () => {
    try {
      const { data } = await getBadgeNewOrderApi();
      dispatch(setBadge({ type: "newOrder", total: data.total }))
    } catch (error) {
      throw error;
    }
  }

  const getBadgeNewDeposit = async () => {
    try {
      const { data } = await getBadgeNewDepositApi();
      dispatch(setBadge({ type: "newDeposit", total: data.total }))
    } catch (error) {
      throw error;
    }
  }

  const getBadgeNewWithdraw = async () => {
    try {
      const { data } = await getBadgeNewWithdrawApi();
      dispatch(setBadge({ type: "newWithdraw", total: data.total }))
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    initFcm()
    getBadgeNewDriver();
    getBadgeNewOrder();
    getBadgeNewDeposit();
    getBadgeNewWithdraw();
    // eslint-disable-next-line
  }, [])

  onMessageListener().then(async (payload) => {
    let data = payload.data
    console.log(data)

    switch (data?.type) {
      case "wallet":
        await Promise.all([
          getBadgeNewDeposit(),
          getBadgeNewWithdraw()
        ])
        break;
      case "order":
        await Promise.all([
          getBadgeNewOrder(),
        ])
        break;
      case "driver":
        await Promise.all([
          getBadgeNewDriver(),
        ])
        break;
      default:
        console.log(data?.action_type)
    }
  }).catch(err => {
    console.log('failed: ', err)
  });

  return (
    <Fragment>
      <PageLoading />
      <div className="sidebar-fixed">
        <div className="container-scroller">
          <Topbar />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} replace />}
                  />
                  {
                    rules.dashboard && (
                      <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Dashboard />} />
                    )
                  }

                  {
                    rules.userMember && (
                      <Route path={`${process.env.PUBLIC_URL}/users`} element={<User />}>
                        <Route index element={<UserList />} />
                        <Route path={"add"} element={<UserAdd />} />
                        <Route path={"edit/:id"} element={<UserEdit />} />
                        <Route path={"history/:id"} element={<UserHistory />} />
                      </Route>
                    )
                  }

                  {
                    rules.riderMember && (
                      <Route path={`${process.env.PUBLIC_URL}/drivers`} element={<Driver />}>
                        <Route index element={<DriverList />} />
                        <Route path={`review`} element={<DriverReviewList />} />
                        <Route path={`edit/:id`} element={<DriverEdit />} />
                        <Route path={`:id`} element={<DriverDetail />}>
                          <Route index element={<DriverTabProfile />} />
                          <Route path={`profile`} element={<DriverTabProfile />} />
                          <Route path={`service-history`} element={<DriverTabServiceHistory />} />
                          <Route path={`deposit-history`} element={<DriverTabDepositHistory />} />
                          <Route path={`withdraw-history`} element={<DriverTabWithdrawHistory />} />
                        </Route>
                      </Route>
                    )
                  }

                  {
                    rules.order && (
                      <Route path={`${process.env.PUBLIC_URL}/orders`} element={<Order />}>
                        <Route index element={<OrderList />} />
                        <Route path={":id"} element={<OrderDetail />} />
                      </Route>)
                  }

                  {
                    rules.sending && (
                      <Route path={`${process.env.PUBLIC_URL}/sending`} element={<Sending />}>
                        <Route index element={<SendingTabOnline />} />
                        <Route path={"online"} element={<SendingTabOnline />} />
                        <Route path={"took-order"} element={<SendingTabTookOrder />} />
                        <Route path={"took-passenger"} element={<SendingTabTookPassenger />} />
                      </Route>
                    )
                  }

                  {
                    rules.saleBilling && (
                      <Route path={`${process.env.PUBLIC_URL}/sale-billing`} element={<SaleBill />}>
                      </Route>
                    )
                  }

                  {
                    rules.report && (
                      <Route path={`${process.env.PUBLIC_URL}/report`} element={<Report />}>
                        <Route index element={<ReportStats />} />
                        <Route path='stats' element={<ReportStats />} />
                        <Route path='car-per-day' element={<ReportCarsPerDay />} />
                        <Route path='trips-per-day' element={<ReportTripsPerDay />} />
                        <Route path='trips-per-car-day' element={<ReportTripsPerCarPerDay />} />
                        <Route path='running-time-per-car-day' element={<ReportRunningTimePerCarPerDay />} />
                        <Route path='satisfaction' element={<ReportSatisfaction />} />
                        <Route path='accident' element={<ReportAccident />} />
                        <Route path='mistake' element={<ReportMistake />} />
                      </Route>
                    )
                  }

                  {
                    rules.appeal && (
                      <Route path={`${process.env.PUBLIC_URL}/appeal`} element={<Appeal />}>
                        <Route index element={<AppealList />} />
                      </Route>
                    )
                  }

                  {
                    rules.review && (
                      <Route path={`${process.env.PUBLIC_URL}/reviews`} element={<Review />}>
                        <Route index element={<ReviewList />} />
                      </Route>
                    )
                  }

                  {
                    rules.deposit && (
                      <Route path={`${process.env.PUBLIC_URL}/credit`} element={<Credit />}>
                        <Route index element={<CreditRequestList />} />
                        <Route path='history' element={<CreditHistoryList />} />
                      </Route>
                    )
                  }

                  {
                    rules.withdraw && (
                      <Route path={`${process.env.PUBLIC_URL}/withdraw`} element={<Withdraw />}>
                        <Route index element={<WithdrawRequestList />} />
                        <Route path='history' element={<WithdrawHistoryList />} />
                        <Route path='process' element={<WithdrawProcess />} />
                      </Route>
                    )
                  }

                  {
                    rules.news && (
                      <Route path={`${process.env.PUBLIC_URL}/news`} element={<News />}>
                        <Route index element={<NewsList />} />
                        <Route path={`add`} element={<NewsForm mode='new' />} />
                        <Route path={`read/:id`} element={<RenderNews />} />
                        <Route path={`edit/:id`} element={<NewsForm mode='edit' />} />
                      </Route>
                    )
                  }

                  {
                    rules.serviceSetting && (
                      <Route path={`${process.env.PUBLIC_URL}/car-service`} element={<CarService />}>
                        <Route index element={<CarServiceList />} />
                        <Route path={`add`} element={<CarServiceForm mode='new' />} />
                        <Route path={`edit/:id`} element={<CarServiceForm mode='edit' />} />
                      </Route>
                    )
                  }

                  {
                    rules.promotionCode && (
                      <Route path={`${process.env.PUBLIC_URL}/promotion-code`} element={<PromotionCode />}>
                        <Route index element={<PromotionCodeList />} />
                        <Route path={`add`} element={<PromotionCodeForm mode='new' />} />
                        <Route path={`edit/:id`} element={<PromotionCodeForm mode='edit' />} />
                      </Route>
                    )
                  }


                  {
                    rules.setting && (
                      <Route path={`${process.env.PUBLIC_URL}/setting`} element={<Setting />}>
                        <Route index element={<SettingInformation />} />
                        <Route path={`admin`} element={<SettingAdmin />} />
                      </Route>
                    )
                  }

                  <Route path={`${process.env.PUBLIC_URL}/profile`} element={<SettingUser />}></Route>

                  {/* route not found */}
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AppLayout;
