import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { setAppLoading } from "../../redux/app.reducer";
import { AsyncPaginate } from "react-select-async-paginate";
import { filterDriversApi } from "../../api/user";
import { deductByUserIdApi } from "../../api/wallet";

const WithdrawProcess = () => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [payload, setPayload] = useState({
    amount: 0,
    userId: "",
    remark: "",
  })

  const filterOnlineUser = async (search, loadedOptions, additional) => {
    try {
      const { data } = await filterDriversApi({
        keyword: search ?? "",
        status: "1",
        page: additional?.page ?? 1,
        limit: 10,
      });

      return ({
        options: data.results?.map(e => ({ value: e.id, label: `${e.userNo}: ${e.firstName} ${e.lastName} (เครดิต ${e.credit})` })) ?? [],
        hasMore: data.meta.hasMore,
        additional: {
          page: additional.page + 1,
        },
      });
    } catch (error) {
      return ({
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      });
    }
  }

  const deductByUserId = async (userId: string, amount: number, remark: string) => {
    try {
      if (userId.length === 0) {
        openSnackbar("Please select driver");
        return
      }
      setLoading(true);
      await deductByUserIdApi({ userId, amount, remark });
      setLoading(false);
      setPayload({
        amount: 0,
        userId: "",
        remark: "",
      });

      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="col-sm-12 col-md-4" style={{ margin: "0 auto" }}>
            <form onSubmit={(e) => {
              e.preventDefault();
              deductByUserId(payload.userId, payload.amount, payload.remark);
            }}>
              <div className="form-group">
                <label className="required">รหัสพนักงาน</label>
                <AsyncPaginate
                  debounceTimeout={500}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                      return {
                        ...styles,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        textOverflow: "ellipsis",
                      }
                    }
                  }}
                  loadOptions={filterOnlineUser}
                  onChange={(e: any) => {
                    setPayload(prev => ({
                      ...prev,
                      userId: e.value,
                    }))
                  }}
                />
              </div>

              <div className="form-group">
                <label className="required">จำนวนเงิน</label>
                <input
                  type="text"
                  required
                  name="amount"
                  className="form-control"
                  value={payload.amount + ""}
                  pattern="\d*"
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val.length === 0) val = "0";
                    if (isNaN(parseInt(val[val.length - 1]))) return;
                    setPayload(prev => ({
                      ...prev,
                      [e.target.name]: +e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="form-group">
                <label>หมายเหตุ</label>
                <input
                  type="text"
                  name="remark"
                  className="form-control"
                  value={payload.remark}
                  onChange={(e) => {
                    setPayload(prev => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="form-group text-right">
                <button type="submit" className={`btn btn-primary`}>บันทึก</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default WithdrawProcess;