import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getProfileUserByApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import { IUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";


const DriverDetail = () => {
  let { pathname } = useLocation();
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [user, setUser] = useState<IUser>({} as IUser);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("");

  const getProfileUserById = async () => {
    try {
      setLoading(true);

      const { data } = await getProfileUserByApi(id!);
      setUser(data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/drivers`)
    }
  }

  useEffect(() => {
    getProfileUserById()
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    setCurrentTab(pathname.split("/").length === 4 ? pathname.split("/")[3] : "profile")
    // eslint-disable-next-line
  }, [pathname])

  return (
    <>
      <Breadcrumb
        title={"ข้อมูลพนักงาน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "พนักงานขับรถ", link: "/drivers" },
          { title: "ข้อมูลพนักงาน" }
        ]}
      />

      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <div className="thumbnail ml-2">
              <img className="lazy img-responsive" src={getSrcImage(user.picture)} alt="" />
            </div>
            <div className="ml-2">
              <h3>{user.firstName} {user.lastName}</h3>
              <h6>รหัสพนักงาน {user.userNo}</h6>
            </div>
          </div>

          <Nav tabs fill>
            <NavItem>
              <NavLink className={currentTab === "profile" ? "active" : ""} onClick={() => navigate(`${process.env.PUBLIC_URL}/drivers/${id}/profile`)} >
                ประวัติพนักงาน
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={currentTab === "service-history" ? "active" : ""} onClick={() => navigate(`${process.env.PUBLIC_URL}/drivers/${id}/service-history`)} >
                ประวัติการบริการ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={currentTab === "deposit-history" ? "active" : ""} onClick={() => navigate(`${process.env.PUBLIC_URL}/drivers/${id}/deposit-history`)} >
                ประวัติการการเติมเครดิต
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={currentTab === "withdraw-history" ? "active" : ""} onClick={() => navigate(`${process.env.PUBLIC_URL}/drivers/${id}/withdraw-history`)} >
                ประวัติการการถอนเงิน
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent>
            <TabPane>
              {
                !loading && <Outlet context={{ user: user }} />
              }
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  )
}

export default DriverDetail;
