import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Meta from "../../interface/meta";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import {
  deletePromotionCodeByIdApi,
  filterPromotionCodeApi,
  updateStatusPromotionCodeApi,
} from "../../api/promotion-code";
import { IPromotionCode } from "../../interface/promotion-code";
import Datatable, { DatatableElement } from "../../component/datatable";
import { setAppLoading } from "../../redux/app.reducer";
import Pagination from "../../component/pagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { filterCarServiceApi } from "../../api/car-service";
import { createRoot } from "react-dom/client";
import shortid from "shortid";

const PromotionCodeList = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState<IPromotionCode[]>([]);
  const name = useRef<HTMLInputElement>(null);
  const status = useRef<HTMLSelectElement>(null);
  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);
  const [serviceTypes, setServiceTypes] = useState<object>();
  const conditions = [
    "ไม่จำกัด",
    "สั่งซื้อครั้งแรก",
    "ใช้ได้...ครั้งเท่านั้น/ท่าน",
    "ใช้ได้...จำนวนครั้ง/วัน",
  ];

  const initData = async () => {
    try {
      const { data } = await filterCarServiceApi({});
      let tmp = {};
      for (const e of data ?? []) {
        tmp[e.id] = e.name;
      }
      setServiceTypes(tmp);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterPromotions = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterPromotionCodeApi({
        name: name.current?.value,
        status: status.current?.value,
        page,
        limit,
      });

      setPromotions(
        data.results?.map((e) => ({ ...e, types: serviceTypes ?? {} })) ?? []
      );
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updateStatusPromotions = async () => {
    try {
      if (action.current?.value === "") return;

      const selected: IPromotionCode[] = table.current?.selected();
      if (selected.length === 0) return;
      setLoading(true);
      const id = selected.map((e) => e.id);
      const status = +action.current!.value;
      if (status === -1) {
        await Promise.all(id.map((e) => deletePromotionCodeByIdApi(e)));
      } else {
        await updateStatusPromotionCodeApi({
          ids: id,
          status: status,
        });
      }
      filterPromotions(meta.page, meta.limit);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (serviceTypes === undefined) return;
    filterPromotions(1, 10);
    // eslint-disable-next-line
  }, [serviceTypes]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  let debounce: any = undefined;

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center flex-row mb-2">
              <h3 className="mt-4 ml-1">
                ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
              </h3>

              <div
                className="d-flex flex-row align-items-end"
                style={{ gap: "1rem" }}
              >
                <div style={{ width: 200 }}>
                  <span className="tx-13 text-muted">ค้นหา</span>
                  <input
                    type="text"
                    className="form-control global_filter"
                    ref={name}
                    placeholder="ชื่อโปรโมชั่นโค้ด"
                    onChange={() => {
                      if (!!debounce) {
                        debounce.cancel();
                      }
                      debounce = _.debounce(() => {
                        filterPromotions(1, meta.limit);
                      }, 500);
                      debounce();
                    }}
                  />
                </div>

                <div style={{ width: 150 }}>
                  <span>สถานะ</span>
                  <select
                    className="form-control"
                    ref={status}
                    onChange={() => {
                      filterPromotions(1, meta.limit);
                    }}
                  >
                    <option value="">ทั้งหมด</option>
                    <option value="1">ใช้งาน</option>
                    <option value="2">ระงับการใช้งาน</option>
                    <option value="3">หมดอายุ</option>
                    <option value="0">ยกเลิก</option>
                  </select>
                </div>

                <div style={{ width: 150 }}>
                  <span>ดำเนินการ</span>
                  <select className="form-control" ref={action}>
                    <option value="">ดำเนินการ...</option>
                    <option value="1">ใช้งาน</option>
                    <option value="0">ระงับการใช้งาน</option>
                    <option value="-1">ลบ</option>
                  </select>
                </div>

                <button
                  onClick={() => {
                    updateStatusPromotions();
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  ดำเนินการ
                </button>
              </div>
            </div>

            <Datatable
              ref={table}
              data={promotions}
              columns={[
                {
                  isCheckbox: true,
                  title: "",
                  data: function (row, type, set) {
                    return "";
                  },
                },

                {
                  title: "ชื่อโปรโมชั่น",
                  width: 200,
                  data: "name",
                },
                {
                  title: "สำหรับ",
                  width: 200,
                  data: null,
                  createdCell(cell, cellData, rowData: IPromotionCode) {
                    createRoot(cell).render(
                      <div>
                        {!!rowData.serviceTypes &&
                        rowData.serviceTypes.length > 0 ? (
                          rowData.serviceTypes?.map((e) => (
                            <div key={shortid.generate()}>
                              - {rowData.types[e] ?? ""}
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "รหัสโค้ด",
                  width: 120,
                  data: "code",
                },
                {
                  title: "ส่วนลด",
                  width: 100,
                  data: null,
                  render(data, type, row: IPromotionCode, meta) {
                    return `${row.discount.toLocaleString("en-US")}${
                      row.discountType === 0 ? "%" : "กีบ"
                    }`;
                  },
                },
                {
                  title: "สั่งซื้อขั้นต่ำ(กีบ)",
                  width: 100,
                  data: "minPrice",
                  render: (data) => {
                    return data.toLocaleString("en-US");
                  },
                },
                {
                  title: "ลดสูงสุด(กีบ)",
                  width: 100,
                  data: "maxDiscount",
                  render: (data) => {
                    return data.toLocaleString("en-US");
                  },
                },
                {
                  title: "เงื่อนไขการใช้",
                  width: 200,
                  data: "condition",
                  render: (data) => {
                    return conditions[data] ?? "";
                  },
                },
                {
                  title: "จำนวน",
                  width: 100,
                  data: "quantity",
                  render: (data) => {
                    return data.toLocaleString("en-US");
                  },
                },
                {
                  title: "ใช้ไป",
                  width: 100,
                  data: "usage",
                  render: (data) => {
                    return data.toLocaleString("en-US");
                  },
                },
                {
                  title: "วันเริ่มต้น",
                  width: 150,
                  data: "begin",
                  render: (data) => {
                    return `${moment(data).format("yyyy-MM-DD HH:mm")}`;
                  },
                },
                {
                  title: "วันเริ่มต้น",
                  width: 150,
                  data: "end",
                  render: (data) => {
                    return `${moment(data).format("yyyy-MM-DD HH:mm")}`;
                  },
                },
                {
                  title: "สถานะ",
                  width: 100,
                  data: "status",
                  render: (data: number) => {
                    switch (data) {
                      case 0:
                        return "<span class='badge badge-defualt'>ยกเลิก</span>";
                      case 1:
                        return "<span class='badge badge-success'>ใช้งาน</span>";
                      case 2:
                        return "<span class='badge badge-danger'>ระงับ</span>";
                      case 3:
                        return "<span class='badge badge-primary'>หมดอายุ</span>";
                      default:
                        return "";
                    }
                  },
                },
              ]}
              actions={[
                {
                  button: {
                    callback(data: IPromotionCode) {
                      navigate(
                        `${process.env.PUBLIC_URL}/promotion-code/edit/${data.id}`
                      );
                    },
                    code: (
                      <button className="btn btn-outline-primary btn-sm mb-1">
                        แก้ไข
                      </button>
                    ),
                  },
                },
              ]}
            />

            <Pagination
              active={meta.page}
              limit={meta.limit}
              onPageChange={(page) => {
                filterPromotions(page, meta.limit);
              }}
              pageRangeDisplayed={5}
              total={meta.totalCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionCodeList;
