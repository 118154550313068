const Menu = [
  { id: "dashboard", link: "dashboard", icon: "images/icon/dashboard.svg", title: "หน้าหลัก" },
  { id: "userMember", link: "users", icon: "images/icon/user.svg", title: "สมาชิก" },
  { id: "riderMember", link: "drivers", icon: "images/icon/driver.svg", title: "พนักงานขับรถ", badgeKey: "newDriver" },
  { id: "order", link: "orders", icon: "images/icon/steering-wheel.svg", title: "เรียกรถ", badgeKey: "newOrder" },
  { id: "sending", link: "sending", icon: "images/icon/traffic.svg", title: "การขนส่ง" },
  // { id: "saleBilling", link: "sale-billing", icon: "images/icon/bar-chart.svg", title: "ยอดขาย" },
  { id: "report", link: "report", icon: "images/icon/profit-report.svg", title: "รายงาน" },
  { id: "appeal", link: "appeal", icon: "images/icon/comment.svg", title: "ร้องเรียน" },
  { id: "review", link: "reviews", icon: "images/icon/review.svg", title: "รีวิว" },
  { id: "deposit", link: "credit", icon: "images/icon/dollar.svg", title: "เติมเครดิต", badgeKey: "newDeposit" },
  { id: "withdraw", link: "withdraw", icon: "images/icon/atm-machine.svg", title: "ถอนเงิน", badgeKey: "newWithdraw" },
  { id: "news", link: "news", icon: "images/icon/loudspeaker.svg", title: "ประกาศ" },
  { id: "serviceSetting", link: "car-service", icon: "images/icon/car.svg", title: "ตั้งค่ารถ" },
  { id: "promotionCode", link: "promotion-code", icon: "images/icon/coupon.svg", title: "โปรโมชั่นโค้ด" },
  { id: "setting", link: "setting", icon: "images/icon/setting.svg", title: "ตั้งค่า" },
];
export default Menu;