import React, { FC } from "react";

interface IPagination {
  total: number;
  limit: number;
  active: number;
  pageRangeDisplayed: number;
  onPageChange: (page: number) => void,
}

const Pagination: FC<IPagination> = ({
  total = 0,
  limit = 1,
  active = 1,
  pageRangeDisplayed = 10,
  onPageChange,
}) => {

  const [state, setState] = React.useState({
    page: [1],
    current: 1,
    pageCount: 1,
  });

  React.useEffect(() => {
    let pageCount = Math.ceil(total / (limit === 0 ? 1 : limit))
    let front = (pageRangeDisplayed - Math.ceil(pageRangeDisplayed / 2) + 1)
    if (front < 0) {
      front = 0
    }
    let current = active
    if (current > pageCount) {
      current = pageCount
    }
    let end = current + pageRangeDisplayed - front
    if (end < pageRangeDisplayed) {
      end = pageRangeDisplayed
    }
    if (end > pageCount) {
      end = pageCount
    }
    let start = end - pageRangeDisplayed
    if (start < 0) {
      start = 0
    }

    let allPage = Array.from(Array(pageCount).keys())
      .map(i => ++i)
      .slice(start, end);

    setState(prevState => ({
      ...prevState,
      page: allPage.length === 0 ? [1] : allPage,
      current: current === 0 ? 1 : current,
      pageCount: pageCount === 0 ? 1 : pageCount,
    }))
  }, [total, limit, active, pageRangeDisplayed])

  return (
    <div className="d-flex justify-content-end">
      <div className="dataTables_paginate paging_simple_numbers">
        <ul className="pagination">
          <li className={`paginate_button page-item previous ${state.current === 1 ? "disabled" : ""}`}>
            <button disabled={state.current === 1} className="page-link" onClick={() => onPageChange(state.current - 1)}>Previous</button>
          </li>
          {
            state.page.map((e) => (
              <li key={`${e}`} className={`paginate_button page-item ${state.current === e ? "active" : ""}`} >
                <button className="page-link" onClick={() => onPageChange(e)}>{e}</button>
              </li>
            ))
          }
          <li className={`paginate_button page-item next ${state.current === state.pageCount ? "disabled" : ""}`}>
            <button disabled={state.current === state.pageCount} className="page-link" onClick={() => onPageChange(state.current + 1)}>Next</button>
          </li>
        </ul>
      </div>
    </div >
  )
}

export default Pagination;