import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";


const Setting = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Breadcrumb
        title={"ตั้งค่า"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ตั้งค่า" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${pathname.split("/").length === 3 ? "" : "active"}`}
                to={`${process.env.PUBLIC_URL}/setting`}
              >
                รายละเอียดบริษัท</Link>
            </li>

            <li className="nav-item mt-2">
              <Link
                className={`nav-link ${pathname.split("/").length === 3 ? "active" : ""}`}
                to={`${process.env.PUBLIC_URL}/setting/admin`}
              >
                ผู้ดูแลระบบ
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Outlet />
    </>
  )
}
export default Setting;
