import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { selectApp } from "../../redux/app.reducer";
import { useAppSelector } from "../../redux/hooks";


const Credit = () => {
  const { pathname } = useLocation();
  const { badge } = useAppSelector(selectApp)

  return (
    <>
      <Breadcrumb
        title={"เติมเครดิต"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "เติมเครดิต" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <Link to={`${process.env.PUBLIC_URL}/credit`} className={`nav-link ${pathname.split("/").length === 3 ? "" : "active"}`}>
                คำขอใหม่
                {
                  badge.newDeposit > 0 && (
                    <span className="badge badge-pill badge-danger ml-1">{badge.newDeposit}</span>
                  )
                }
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`${process.env.PUBLIC_URL}/credit/history`} className={`nav-link ${pathname.split("/").length === 3 ? "active" : ""}`}>ประวัติ</Link>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  )
}
export default Credit;
