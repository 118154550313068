import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { filterOrderReportCarPerDayApi } from "../../api/order";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const ReportCarsPerDay = () => {
  const { from, to } = useOutletContext<{ from: number, to: number }>()
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<any>();

  const filterOrderReportCarPerDay = async (from: number, to: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrderReportCarPerDayApi(from, to);
      setValue(data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterOrderReportCarPerDay(from, to)
    // eslint-disable-next-line
  }, [from, to]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="">วัน/เดือน/ปี</th>
                  <th className="text-right">จำนวนรถ(คัน)</th>
                </tr>
              </thead>
              <tbody>
                {
                  value && Object.keys(value).map(e => (
                    <tr key={e}>
                      <td>{e}</td>
                      <td className="text-right">
                        {
                          value[e].toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          })
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportCarsPerDay;
