import { Outlet } from "react-router-dom";


const Driver = () => {
  return (
    <>
      <Outlet />
    </>
  )
}
export default Driver;
