import Breadcrumb from "../../component/breadcrumb";
import { Link, Outlet, useLocation } from "react-router-dom";

const PromotionCode = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <Breadcrumb
        title={"โปรโมชั่นโค้ด"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "โปรโมชั่นโค้ด" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname.split("/").length === 3 ? "" : "active"}`}
                to={`${process.env.PUBLIC_URL}/promotion-code`}
              >โปรโมชั่นโค้ดทั้งหมด</Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${pathname.split("/").length === 3 ? "active" : ""}`}
                to={`${process.env.PUBLIC_URL}/promotion-code/add`}
              >เพิ่มโปรโมชั่นโค้ด</Link>
            </li>
          </ul>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default PromotionCode;