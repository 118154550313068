import { useEffect, useRef, useState } from "react";
import { changePasswordByMeApi, updateUserProfileByMeApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import { IAdminUpdateUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { selectAuth, setProfile } from "../../redux/authentication.reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const $ = require('jquery');
$.dropify = require("dropify");

const SettingUser = () => {
  const { user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [updateProfile, setUpdateProfile] = useState<IAdminUpdateUser>({ ...user })
  const oldPassword = useRef<HTMLInputElement>(null);
  const newPassword = useRef<HTMLInputElement>(null);
  const confirmNewPassword = useRef<HTMLInputElement>(null);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);

  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // oldPassword.current?.setAttribute("type", "text")
      newPassword.current?.setAttribute("type", "text")
      confirmNewPassword.current?.setAttribute("type", "text")
    } else {
      // oldPassword.current?.setAttribute("type", "password")
      newPassword.current?.setAttribute("type", "password")
      confirmNewPassword.current?.setAttribute("type", "password")
    }
  }

  const updateUserProfile = async (payload: IAdminUpdateUser) => {
    try {
      setLoading(true);
      const { data } = await updateUserProfileByMeApi(payload);
      dispatch(setProfile(data));

      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const changePasswordByMe = async (payload: { oldPassword: string, newPassword: string }) => {
    try {
      setLoading(true);
      await changePasswordByMeApi(payload);
      openSnackbar("Successfuly");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!user.picture ? `${getSrcImage(user.picture)}` : "",
    });
    drEvent.on('dropify.afterClear', function (event, element) {
      setUpdateProfile(prev => ({
        ...prev,
        picture: "",
      }))
    });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"ตั้งค่าโปรไฟล์"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ตั้งค่าโปรไฟล์" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row border-bottom">
                <div className="col-12">
                  <h5>ข้อมูล</h5>
                </div>
              </div>

              <form className="mt-4" onSubmit={(e) => {
                e.preventDefault();
                updateUserProfile(updateProfile)
              }}>
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">รูป</label>
                  <div className="col-sm-5">
                    <input
                      type="file"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setUpdateProfile(prev => ({
                            ...prev,
                            picture: reader.result as string,
                          }))
                        };
                      }}
                      className="dropify"
                      data-max-file-size="1M"
                      data-allowed-file-extensions="jpg png jpeg"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label required">ชื่อ</label>
                  <div className="col-sm-5">
                    <input className="form-control" type="text" required
                      defaultValue={user.firstName}
                      onChange={(e => {
                        setUpdateProfile(prev => ({
                          ...prev,
                          firstName: e.target.value
                        }))
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">นามสกุล</label>
                  <div className="col-sm-5">
                    <input className="form-control" type="text"
                      defaultValue={user.lastName}
                      onChange={(e => {
                        setUpdateProfile(prev => ({
                          ...prev,
                          lastName: e.target.value
                        }))
                      })}
                    />
                  </div>
                </div>

                <div className="form-group text-right">
                  <button type="submit" className="btn btn-primary">บันทึก</button>
                </div>
              </form>
              <div className="row border-bottom">
                <div className="col-12">
                  <h5>เปลี่ยนรหัสผ่าน</h5>
                </div>
              </div>

              <form className="mt-4" onSubmit={(e) => {
                e.preventDefault();
                changePasswordByMe({
                  newPassword: newPassword.current?.value ?? "",
                  oldPassword: oldPassword.current?.value ?? "",
                })
              }}>
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label required">อีเมล</label>
                  <div className="col-sm-5">
                    <input className="form-control" type="email" disabled autoComplete="username" defaultValue={user.email} />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-labelrequired required">รหัสปัจจุบัน</label>
                  <div className="col-sm-5">
                    <input
                      ref={oldPassword}
                      required
                      type="password"
                      className="form-control ml-auto"
                      autoComplete="old-password"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-labelrequired required">รหัสใหม่</label>
                  <div className="col-sm-5">
                    {!passwordMatch && (<span className="text-danger mb-1">รหัสผ่านไม่ตรงกัน</span>)}
                    <input
                      ref={newPassword}
                      required
                      type="password"
                      className="form-control ml-auto"
                      autoComplete="new-password"
                      onChange={(e => {
                        setPasswordMatch(e.target.value === confirmNewPassword.current?.value);
                      })}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-labelrequired required">ยืนยันรหัส</label>
                  <div className="col-sm-5">
                    <input
                      ref={confirmNewPassword}
                      required
                      type="password"
                      className="form-control ml-auto"
                      autoComplete="new-password"
                      onChange={(e => {
                        setPasswordMatch(e.target.value === newPassword.current?.value);
                      })}
                    />
                    <div className="form-check mt-4 ml-2" >
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" onChange={(e) => handleShowPassword(e)} />Show Password
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group text-right">
                  <button disabled={
                    !passwordMatch || newPassword.current?.value.length === 0 || confirmNewPassword.current?.value.length === 0 || oldPassword.current?.value.length === 0
                  } type="submit" className="btn btn-warning">เปลี่ยนรหัสผ่าน</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingUser;