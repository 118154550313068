import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Datatable from "../../component/datatable";
import Meta from "../../interface/meta";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { setAppLoading } from "../../redux/app.reducer";
import { filterWalletsApi } from "../../api/wallet";
import { IWallet } from "../../interface/wallet";
import ModalViewImage from "../../component/modal-view-image";
import { createRoot } from "react-dom/client";
import Pagination from "../../component/pagination";
import { moneyDisplay } from "../../utils/money";

const WithdrawHistoryList = () => {
  interface IFilter {
    begin: string;
    end: string;
    status: string;
  }
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [loading, setLoading] = useState(false);
  const name = useRef<HTMLInputElement>(null);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState<IFilter>({
    begin: "",
    end: "",
    status: "0,1",
  });

  let debounce: any;

  const filterWithdrawRequestList = async (
    filter,
    page: number,
    limit: number
  ) => {
    try {
      setLoading(true);
      const { data } = await filterWalletsApi({
        type: "2",
        status: filter.status,
        userName: name.current?.value,
        begin:
          filter.begin !== "" ? moment(filter.begin).utc().unix() : undefined,
        end: filter.end !== "" ? moment(filter.end).utc().unix() : undefined,
        page,
        limit,
      });

      setWallets(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterWithdrawRequestList(filter, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);
  return (
    <>
      <div className="row">
        <div className="col-4-lg my-1 ml-auto mr-3">
          <div className="input-group input-daterange d-flex align-items-center">
            <input
              type="date"
              className="form-control"
              value={filter.begin}
              placeholder="เริ่มต้น"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  begin: e.target.value,
                }));
              }}
            />
            <div className="input-group-addon mx-2">ถึง</div>
            <input
              type="date"
              className="form-control"
              value={filter.end}
              placeholder="สิ้นสุด"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  end: e.target.value,
                }));
              }}
            />
            {/* <button id="search" className="btn btn-primary btn-sm ml-2">Export</button> */}
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-row mb-2">
            <h3 className="mt-4 ml-1">
              ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
            </h3>
            <div
              className="d-flex flex-row align-items-end"
              style={{ gap: "1rem" }}
            >
              <div>
                <span className="text-muted tx-13">ค้นหา</span>
                <input
                  type="text"
                  className="form-control global_filter"
                  ref={name}
                  placeholder="ชื่อ"
                  onChange={() => {
                    if (!!debounce) {
                      debounce.cancel();
                    }
                    debounce = _.debounce(() => {
                      filterWithdrawRequestList(filter, 1, meta.limit);
                    }, 500);
                    debounce();
                  }}
                />
              </div>

              <div style={{ width: 150 }}>
                <span className="text-muted tx-13">สถานะ</span>
                <select
                  className="form-control"
                  value={filter.status}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="0,1">ทั้งหมด</option>
                  <option value="0">ยกเลิก</option>
                  <option value="1">อนุมัติ</option>
                </select>
              </div>
            </div>
          </div>

          <Datatable
            data={wallets}
            columns={[
              {
                title: "Transaction No.",
                width: 120,
                data: "code",
              },
              {
                title: "เวลา",
                width: 150,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return row.createdAt !== ""
                    ? moment(row.createdAt).format("DD/MM/yyyy HH:mm:ss")
                    : "";
                },
              },
              {
                title: "ผู้ถอน",
                width: 200,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return `${row.createdUser.firstName} ${row.createdUser.lastName}`;
                },
              },
              {
                title: "รหัสพนักงาน.",
                width: 100,
                data: null,
                createdCell(
                  cell,
                  cellData,
                  rowData: IWallet,
                  rowIndex,
                  colIndex
                ) {
                  createRoot(cell).render(
                    <div
                      className="hover-underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          `${process.env.PUBLIC_URL}/drivers/${rowData.userId}/profile`
                        )
                      }
                    >
                      {rowData.user.userNo}
                    </div>
                  );
                },
              },
              {
                title: "ชื่อ-นามสกุล",
                width: "200px",
                data: null,
                createdCell(
                  cell,
                  cellData,
                  rowData: IWallet,
                  rowIndex,
                  colIndex
                ) {
                  createRoot(cell).render(
                    <div
                      className="hover-underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          `${process.env.PUBLIC_URL}/drivers/${rowData.userId}/profile`
                        )
                      }
                    >
                      {rowData.user.firstName} {rowData.user.lastName}
                    </div>
                  );
                },
              },
              {
                title: "จำนวนเงิน",
                width: 150,
                data: "amount",
                render(data, type, row: IWallet, meta) {
                  return `<span class="text-success">${moneyDisplay(
                    Math.abs(data)
                  )}</span>`;
                },
              },
              {
                title: "เครดิตคงเหลือ",
                width: 150,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return `${moneyDisplay(row.user.credit)}`;
                },
              },
              {
                title: "ธนาคาร",
                width: 150,
                data: "bankAccount.bank.name",
              },
              {
                title: "ชื่อบัญชี",
                width: 150,
                data: "bankAccount.accountName",
              },
              {
                title: "หมายเลขบัญชี",
                width: 150,
                data: "bankAccount.accountNumber",
              },
              {
                title: "สถานะ",
                data: "status",
                width: 80,
                render(data: number, type, row, meta) {
                  switch (data) {
                    case 0:
                      return "ยกเลิก";
                    case 1:
                      return "อนุมัติ";
                    case 2:
                      return "รอดำเนินการ";
                    case 3:
                      return "กำลังตรวจสอบ";
                    default:
                      return "";
                  }
                },
              },
            ]}
          />

          <Pagination
            active={meta.page}
            limit={meta.limit}
            onPageChange={(page) => {
              filterWithdrawRequestList(filter, page, meta.limit);
            }}
            pageRangeDisplayed={5}
            total={meta.totalCount}
          />
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default WithdrawHistoryList;
