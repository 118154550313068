import { AxiosResponse } from "axios";
import { ICreateNews, INews } from "../interface/news";
import { http } from "../utils/http";

export const filterNewsApi = async (payload: {
  keyword?: string;
  status?: number[];
  forRoles?: string[];
  page: number;
  limit: number;
}) => {
  return await http.get("/v1/admin/news/filter", payload)
};

export const createNewsApi = async (payload: ICreateNews) => {
  return await http.post("/v1/admin/news", payload)
};

export const deleteNewsByIdApi = async (id: string) => {
  return await http.del(`/v1/admin/news/${id}`)
};

export const getPublicNewsByIdApi = async (id: string): Promise<AxiosResponse<INews>> => {
  return await http.get(`/v1/news/${id}`, {}, false)
};

export const getNewsByIdApi = async <T extends any>(id: string): Promise<AxiosResponse<T>> => {
  return await http.get(`/v1/admin/news/${id}`)
};

export const updateNewsByIdApi = async (id: string, payload: ICreateNews) => {
  return await http.put(`/v1/admin/news/${id}`, payload)
};