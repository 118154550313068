import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { IPromotionCodeRequest } from "../../interface/promotion-code";
import { setAppLoading } from "../../redux/app.reducer";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { ICarService } from "../../interface/car-service";
import { filterCarServiceApi } from "../../api/car-service";
import {
  createPromotionCodeApi,
  getPromotionCodeByIdApi,
  updatePromotionCodeByIdApi,
} from "../../api/promotion-code";

interface IPromotionCodeForm {
  mode: "new" | "edit";
}

const PromotionCodeForm: FC<IPromotionCodeForm> = ({ mode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(false);
  const [serviceOptions, setServiceOptions] = useState<
    MultiValue<{ value: string; label: string }>
  >([]);
  const [serviceTypes, setServiceTypes] = useState<
    MultiValue<{ value: string; label: string }>
  >([]);
  const [date, setDate] = useState([
    moment().format("yyyy-MM-DD"),
    moment().format("yyyy-MM-DD"),
  ]);
  const [time, setTime] = useState(["00:00:00", "00:00:00"]);
  const [promotion, setPromotion] = useState<IPromotionCodeRequest>({
    name: "",
    detail: "",
    serviceTypes: [],
    code: "",
    discount: 0,
    discountType: 0,
    minPrice: 0,
    maxDiscount: 0,
    condition: 0,
    conditionQuantity: 0,
    begin: 0,
    end: 0,
    quantity: 0,
    status: 0,
    payCondition: 0,
  });

  const getPromotionById = async (services: ICarService[]) => {
    try {
      if (mode === "new") return;
      const { data } = await getPromotionCodeByIdApi(id!);
      setPromotion({
        name: data.name,
        detail: data.detail,
        serviceTypes: data.serviceTypes,
        code: data.code,
        discount: data.discount,
        discountType: data.discountType,
        minPrice: data.minPrice,
        maxDiscount: data.maxDiscount,
        condition: data.condition,
        conditionQuantity: data.conditionQuantity,
        begin: moment(data.begin).unix(),
        end: moment(data.end).unix(),
        quantity: data.quantity,
        status: data.status,
        payCondition: data.payCondition,
      });

      let type =
        data.serviceTypes?.reduce((c, e) => {
          let service = services.find((service) => service.id === e);
          if (!service) {
            return c;
          }
          return [...c, { value: service!.id, label: service!.name }];
        }, [] as MultiValue<{ value: string; label: string }>) ?? [];

      setServiceTypes(type);
      setDate([
        moment(data.begin).format("yyyy-MM-DD"),
        moment(data.end).format("yyyy-MM-DD"),
      ]);
      setTime([
        moment(data.begin).format("HH:mm:ss"),
        moment(data.end).format("HH:mm:ss"),
      ]);
    } catch (e: any) {
      throw e;
    }
  };

  const initData = async () => {
    try {
      setLoading(true);

      const service = await filterCarServiceApi({});
      await getPromotionById(service.data ?? []);

      setServiceOptions(
        service.data?.map((e: ICarService) => ({
          value: e.id,
          label: e.name,
        })) ?? []
      );

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/promotion-code`);
    }
  };

  const createPromotion = async () => {
    try {
      setLoading(true);
      const payload: IPromotionCodeRequest = {
        ...promotion,
        begin: moment(`${date[0]} ${time[0]}`).unix(),
        end: moment(`${date[1]} ${time[1]}`).unix(),
        serviceTypes: serviceTypes.map((e) => e.value),
      };
      const { data } = await createPromotionCodeApi(payload);

      setLoading(false);
      openSnackbar("Successfuly");
      navigate(`${process.env.PUBLIC_URL}/promotion-code/edit/${data.id}`);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updatePromotion = async () => {
    try {
      setLoading(true);
      const payload: IPromotionCodeRequest = {
        ...promotion,
        begin: moment(`${date[0]} ${time[0]}`).unix(),
        end: moment(`${date[1]} ${time[1]}`).unix(),
        serviceTypes: serviceTypes.map((e) => e.value),
      };
      await updatePromotionCodeByIdApi(id!, payload);

      setLoading(false);
      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <div>
        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (mode === "new") {
                      createPromotion();
                    } else {
                      updatePromotion();
                    }
                  }}
                >
                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      ชื่อโปรโมชั่น
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        required
                        name="name"
                        className="form-control"
                        placeholder="ชื่อโปรโมชั่น"
                        value={promotion.name}
                        onChange={(e) => {
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      ส่วนลดสำหรับ
                    </label>
                    <div className="col-md-4">
                      <Select
                        isMulti
                        onChange={(e) => {
                          setServiceTypes(e);
                        }}
                        value={serviceTypes}
                        options={serviceOptions}
                        // isDisabled={mode === "edit"}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      รหัสโค้ด
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        required
                        name="code"
                        className="form-control"
                        placeholder="รหัสโค้ด"
                        value={promotion.code}
                        onChange={(e) => {
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      ส่วนลด
                    </label>
                    <div className="col-2">
                      <input
                        type="text"
                        required
                        name="discount"
                        className="form-control"
                        placeholder="ส่วนลด"
                        value={promotion.discount + ""}
                        pattern="\d*"
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val.length === 0) val = "0";
                          if (isNaN(parseInt(val[val.length - 1]))) return;
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-2">
                      <select
                        name="discountType"
                        className="form-control"
                        value={promotion.discountType + ""}
                        onChange={(e) => {
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      >
                        <option value="0">%</option>
                        <option value="1">กีบ</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      สั่งซื้อขั้นต่ำ(กีบ)
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        required
                        name="minPrice"
                        className="form-control"
                        placeholder="ขั้นต่ำ"
                        value={promotion.minPrice + ""}
                        pattern="\d*"
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val.length === 0) val = "0";
                          if (isNaN(parseInt(val[val.length - 1]))) return;
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      ลดสูงสุด(กีบ)
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        required
                        name="maxDiscount"
                        className="form-control"
                        placeholder="สูงสุด"
                        value={promotion.maxDiscount + ""}
                        pattern="\d*"
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val.length === 0) val = "0";
                          if (isNaN(parseInt(val[val.length - 1]))) return;
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label ">
                      เงื่อนไขการใช้
                    </label>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-8">
                          <select
                            name="condition"
                            value={promotion.condition + ""}
                            className="form-control"
                            onChange={async (e) => {
                              setPromotion((prev) => ({
                                ...prev,
                                [e.target.name]: +e.target.value,
                              }));
                            }}
                          >
                            <option value="0">ไม่จำกัด</option>
                            <option value="1">สั่งซื้อครั้งแรก</option>
                            <option value="2">
                              ใช้ได้...ครั้งเท่านั้น/ท่าน
                            </option>
                            <option value="3">ใช้ได้...จำนวนครั้ง/วัน</option>
                          </select>
                        </div>

                        <div className="col-4">
                          {(promotion.condition === 2 ||
                            promotion.condition === 3) && (
                            <input
                              type="text"
                              required
                              name="conditionQuantity"
                              className="form-control"
                              placeholder="ครั้ง"
                              value={promotion.conditionQuantity + ""}
                              pattern="\d*"
                              onChange={(e) => {
                                let val = e.target.value;
                                if (val.length === 0) val = "0";
                                if (isNaN(parseInt(val[val.length - 1])))
                                  return;
                                setPromotion((prev) => ({
                                  ...prev,
                                  [e.target.name]: +e.target.value,
                                }));
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      จำนวน
                    </label>
                    <div className="col-md-4">
                      <input
                        type="text"
                        required
                        name="quantity"
                        className="form-control"
                        placeholder="จำนวน"
                        value={promotion.quantity + ""}
                        pattern="\d*"
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val.length === 0) val = "0";
                          if (isNaN(parseInt(val[val.length - 1]))) return;
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      เงื่อนไขการจ่ายส่วนต่าง
                    </label>
                    <div className="col-md-4">
                      <select
                        name="payCondition"
                        value={promotion.payCondition + ""}
                        className="form-control"
                        onChange={async (e) => {
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: +e.target.value,
                          }));
                        }}
                      >
                        <option value="0">
                          หักจากคนขับ (ไม่เพิ่มเครดิตให้คนขับ)
                        </option>
                        <option value="1">
                          หักจากระบบ (เพิ่มเครดิตให้คนขับ)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      วันเริ่มต้น
                    </label>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-8">
                          <input
                            type="date"
                            required
                            className="form-control"
                            value={date[0]}
                            onChange={(e) => {
                              setDate((date) => [e.target.value, date[1]]);
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <input
                            type="time"
                            required
                            className="form-control"
                            value={time[0]}
                            onChange={(e) => {
                              setTime((time) => [e.target.value, time[1]]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      วันสิ้นสุด
                    </label>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-8">
                          <input
                            type="date"
                            required
                            className="form-control"
                            value={date[1]}
                            onChange={(e) => {
                              setDate((date) => [date[0], e.target.value]);
                            }}
                          />
                        </div>
                        <div className="col-4">
                          <input
                            type="time"
                            required
                            className="form-control"
                            value={time[1]}
                            onChange={(e) => {
                              setTime((time) => [time[0], e.target.value]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-md-3 col-form-label">
                      หมายเหตุ
                    </label>
                    <div className="col-md-4">
                      <textarea
                        className="form-control"
                        name="detail"
                        style={{ minHeight: 150, maxWidth: "100%" }}
                        value={promotion.detail}
                        onChange={(e) => {
                          setPromotion((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-3"></div>
                    <div className="col-md-4 text-right">
                      <button
                        type="button"
                        className={`btn btn-light mr-2`}
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/promotion-code`);
                        }}
                      >
                        ย้อนกลับ
                      </button>

                      <button
                        type="submit"
                        className={`btn btn-${
                          mode === "new" ? "primary" : "warning"
                        }`}
                      >
                        {mode === "new" ? "บันทึก" : "แก้ไข"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionCodeForm;
