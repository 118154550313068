import { AxiosResponse } from "axios";
import { ICarService, ICarServiceRequest } from "../interface/car-service";
import { http } from "../utils/http";

export const filterCarServiceApi = async (payload: {
  status?: number;
}) => {
  return await http.get("/v1/service-car-type/filter", payload)
};

export const createCarServiceApi = async (payload: ICarServiceRequest) => {
  return await http.post("/v1/admin/service-car-type", payload)
};

export const updateCarServiceByIdApi = async (id: string, payload: ICarServiceRequest) => {
  return await http.put(`/v1/admin/service-car-type/${id}`, payload)
};

export const getCarServiceByIdApi = async (id: string):Promise<AxiosResponse<ICarService>> => {
  return await http.get(`/v1/service-car-type/${id}`)
};

