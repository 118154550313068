import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createCarServiceApi, getCarServiceByIdApi, updateCarServiceByIdApi } from "../../api/car-service";
import { ICarService, ICarServiceRequest } from "../../interface/car-service";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { currencySymbol } from "../../utils/money";

interface ICarServiceForm {
  mode: "new" | "edit"
}
const CarServiceForm: FC<ICarServiceForm> = ({ mode }) => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })

  const [loading, setLoading] = useState(false);

  const [carService, setCarService] = useState<ICarServiceRequest>({
    name: "",
    detail: "",
    status: 1,
    areaDistance: 0,
    serviceType: 0,
    startPrice: 0,
    chargePerKilometer: 0,
    commissionPct: 0,
    chargePerTraffic: 0,
    callPrice: 0,
  })


  const createCarService = async () => {
    try {
      setLoading(true);

      await createCarServiceApi(carService);

      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/car-service`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateCarService = async () => {
    try {
      setLoading(true);

      await updateCarServiceByIdApi(id!, carService);

      setLoading(false);
      openSnackbar("Successfuly");
      setTimeout(() => {
        navigate(`${process.env.PUBLIC_URL}/car-service`)
      }, 500)
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const getCarServiceById = async () => {
    try {
      setLoading(true);

      const { data } = await getCarServiceByIdApi(id!);
      data as ICarService

      setCarService(data)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/car-service`)
    }
  }

  useEffect(() => {
    if (mode === "edit") {
      getCarServiceById();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])
  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={(e) => {
                e.preventDefault();
                if (mode === "edit") {
                  updateCarService()
                } else {
                  createCarService();
                }
              }}>
                <div className="row mb-2">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>เพิ่มบริการรถ</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <label htmlFor="" className="col-sm-3 col-form-label">ชื่อ</label>
                              <div className="col-sm-3">
                                <input
                                  type="text"
                                  required
                                  className="form-control"
                                  placeholder="มอเตอร์ไซต์"
                                  value={carService.name}
                                  onChange={(e) => {
                                    setCarService(prev => ({
                                      ...prev,
                                      name: e.target.value,
                                    }))
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label htmlFor="" className="col-sm-3 col-form-label">รูปแบบการบริการ</label>
                              <div className="col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={carService.detail}
                                  onChange={(e) => {
                                    setCarService(prev => ({
                                      ...prev,
                                      detail: e.target.value,
                                    }))
                                  }}
                                />
                              </div>
                            </div>


                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="name">สถานะ</label>
                              </div>
                              <div className="col-lg-3">
                                <select
                                  className="form-control"
                                  value={carService.status}
                                  onChange={(e) => {
                                    setCarService(prev => ({
                                      ...prev,
                                      status: +e.target.value,
                                    }))
                                  }}>
                                  <option value="1">ใช้งาน</option>
                                  <option value="0">ระงับ</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>ระยะการให้บริการ</h4>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-3 col-form-label">ระยะทางรับงานครอบคลุม(km)</div>
                          <div className="col-sm-4">
                            <input
                              type="number"
                              step={1}
                              min={0}
                              className="form-control"
                              style={{ width: 80 }}
                              value={carService.areaDistance}
                              onChange={(e) => {
                                setCarService(prev => ({
                                  ...prev,
                                  areaDistance: +e.target.value,
                                }))
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>ค่าบริการ</h4>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-3">รูปแบบการคิดว่าบริการ</div>
                          <div className="col-sm-4">

                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="serviceType0"
                                name="serviceType"
                                className="custom-control-input"
                                value={0}
                                checked={carService.serviceType === 0}
                                onChange={(e) => {
                                  setCarService(prev => ({
                                    ...prev,
                                    serviceType: +e.target.value,
                                  }))
                                }}
                              />
                              <label className="custom-control-label" htmlFor="serviceType0">คิดค่าบริการตามระบบ</label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id="serviceType1"
                                name="serviceType"
                                className="custom-control-input"
                                value={1}
                                checked={carService.serviceType === 1}
                                onChange={(e) => {
                                  setCarService(prev => ({
                                    ...prev,
                                    serviceType: +e.target.value,
                                  }))
                                }}
                              />
                              <label className="custom-control-label" htmlFor="serviceType1">คิดค่าบริการตามจริง/มิเตอร์/แล้วแต่ตกลงกับผู้ให้บริการ</label>
                            </div>
                          </div>
                        </div>

                        {
                          carService.serviceType === 1 ? (
                            <div className="form-group row">
                              <div className="col-3"><span className="">ค่าเรียกรถ ({currencySymbol})</span></div>
                              <div className="col-sm-4">
                                <input
                                  type="number"
                                  className="form-control"
                                  min={0}
                                  step={1}
                                  style={{ width: 80 }}
                                  value={carService.callPrice}
                                  onChange={(e) => {
                                    setCarService(prev => ({
                                      ...prev,
                                      callPrice: +e.target.value,
                                    }))
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="form-group row">
                                <div className="col-3"><span className="">ราคาเริ่มต้น(2 km) ({currencySymbol})</span></div>
                                <div className="col-sm-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    step={1}
                                    style={{ width: 80 }}
                                    value={carService.startPrice}
                                    onChange={(e) => {
                                      setCarService(prev => ({
                                        ...prev,
                                        startPrice: +e.target.value,
                                      }))
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <div className="col-3"><span className="">ค่าบริการมาตรฐาน({currencySymbol})/กิโลเมตร</span></div>
                                <div className="col-sm-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    step={1}
                                    style={{ width: 80 }}
                                    value={carService.chargePerKilometer}
                                    onChange={(e) => {
                                      setCarService(prev => ({
                                        ...prev,
                                        chargePerKilometer: +e.target.value,
                                      }))
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <div className="col-3"><span className="">ค่าคอมมิชชั่นตามระยะทาง(%)</span></div>
                                <div className="col-sm-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    step={1}
                                    style={{ width: 80 }}
                                    value={carService.commissionPct}
                                    onChange={(e) => {
                                      setCarService(prev => ({
                                        ...prev,
                                        commissionPct: +e.target.value,
                                      }))
                                    }}
                                  />
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                <div className="col-3"><span className="">ค่าสถาพการจราจร/นาที</span></div>
                                <div className="col-sm-4">
                                  <input
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    step={1}
                                    style={{ width: 80 }}
                                    value={carService.chargePerTraffic}
                                    onChange={(e) => {
                                      setCarService(prev => ({
                                        ...prev,
                                        chargePerTraffic: +e.target.value,
                                      }))
                                    }}
                                  />
                                </div>
                              </div> */}
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <button className="btn btn-outline-primary mr-2 ml-auto" onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/car-service`)
                  }}>ยกเลิก</button>
                  <button type="submit" className="btn btn-primary mr-3">บันทึก</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CarServiceForm;
