import { FC, useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Datatable, { DatatableElement } from "../../component/datatable";
import { ICreateAdmin } from "../../interface/user";

interface IAddAdminModal {
  show: boolean,
  toggle: Function,
  onSubmit: (data: ICreateAdmin) => void,
}
const AddAdminModal: FC<IAddAdminModal> = ({ show, toggle, onSubmit }) => {
  const password = useRef<HTMLInputElement>(null);
  const confirmPassword = useRef<HTMLInputElement>(null);
  const tableRules = useRef<DatatableElement>(null);
  const [reqAddUser, setReqAddUser] = useState<ICreateAdmin>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    status: 0,
    rule: {
      dashboard: false,
      userMember: false,
      riderMember: false,
      order: false,
      sending: false,
      saleBilling: false,
      report: false,
      appeal: false,
      review: false,
      deposit: false,
      withdraw: false,
      news: false,
      serviceSetting: false,
      promotionCode: false,
      setting: false,
    }
  });

  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);

  const rules = [
    { id: "dashboard", title: "หน้าหลัก" },
    { id: "userMember", title: "สมาชิก" },
    { id: "riderMember", title: "พนักงานขับรถ" },
    { id: "order", title: "เรียกรถ" },
    { id: "sending", title: "การขนส่ง" },
    { id: "saleBilling", title: "ยอดขาย" },
    { id: "report", title: "รายงาน" },
    { id: "appeal", title: "ร้องเรียน" },
    { id: "review", title: "รีวิว" },
    { id: "deposit", title: "เติมเครดิต" },
    { id: "withdraw", title: "ถอนเงิน" },
    { id: "news", title: "ประกาศ" },
    { id: "serviceSetting", title: "ตั้งค่าเรียกบริการ" },
    { id: "promotionCode", title: "โปรโมชั่นโค้ด" },
    { id: "setting", title: "ตั้งค่า" },
  ]
  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      password.current?.setAttribute("type", "text")
      confirmPassword.current?.setAttribute("type", "text")
    } else {
      password.current?.setAttribute("type", "password")
      confirmPassword.current?.setAttribute("type", "password")
    }
  }

  useEffect(() => {
    setReqAddUser({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      status: 0,
      rule: {
        dashboard: false,
        userMember: false,
        riderMember: false,
        order: false,
        sending: false,
        saleBilling: false,
        report: false,
        appeal: false,
        review: false,
        deposit: false,
        withdraw: false,
        news: false,
        serviceSetting: false,
        promotionCode: false,
        setting: false,
      }
    });
  }, [show])

  return (
    <Modal size="lg" isOpen={show} toggle={() => toggle()} centered={false}>
      <div className="modal-title modal-header">
        <h5 className="modal-title">เพิ่มผู้ดูแลระบบ</h5>
        <button type="button" onClick={() => toggle()} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <form autoComplete="false" onSubmit={(e) => {
          e.preventDefault();
          let selectedRules = tableRules.current?.selected();
          for (const rule of selectedRules) {
            reqAddUser.rule[rule.id] = true;
          }
          onSubmit(reqAddUser)
        }}>
          <div className="border-bottom mb-3">
            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="required">ชื่อ</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input className="form-control" type="text" required
                  value={reqAddUser.firstName}
                  onChange={(e => {
                    setReqAddUser(prev => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  })}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="">นามสกุล</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input className="form-control" type="text"
                  value={reqAddUser.lastName}
                  onChange={(e => {
                    setReqAddUser(prev => ({
                      ...prev,
                      lastName: e.target.value,
                    }))
                  })}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="required">อีเมล</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input className="form-control" type="email" required autoComplete="false"
                  value={reqAddUser.email}
                  onChange={(e => {
                    setReqAddUser(prev => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  })}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="required">รหัสผ่าน</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                {!passwordMatch && (<span className="text-danger mb-1">รหัสผ่านไม่ตรงกัน</span>)}
                <input ref={password} className="form-control" autoComplete="new-password" type="password" required
                  value={reqAddUser.password}
                  onChange={(e => {
                    setPasswordMatch(password.current?.value === confirmPassword.current?.value);

                    setReqAddUser(prev => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  })}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="name" className="required">ยืนยันรหัสผ่าน</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <input ref={confirmPassword} className="form-control" autoComplete="false" type="password" required
                  onChange={(e => {
                    setPasswordMatch(password.current?.value === confirmPassword.current?.value);
                  })}
                />
                <div className="form-check mt-4 ml-2" >
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" onChange={(e) => handleShowPassword(e)} />Show Password
                    <i className="input-helper"></i>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3">
                <label htmlFor="name">สถานะ</label>
              </div>
              <div className="col-12 col-md-9 col-lg-8">
                <label className="toggle-switch toggle-switch-success">
                  <input type="checkbox"
                    checked={reqAddUser.status === 1}
                    onChange={(e => {
                      setReqAddUser(prev => ({
                        ...prev,
                        status: e.target.checked ? 1 : 0,
                      }))
                    })}
                  />
                  <span className="toggle-slider round"></span>
                </label>

              </div>
            </div>
          </div>
          <div>
            <h4>กำหนดสิทธิ์การใช้งาน</h4>
            <Datatable
              id="dataTable2"
              ref={tableRules}
              data={rules}
              columns={[
                {
                  isCheckbox: true,
                  title: "",
                  data: function (row, type, set) {
                    return '';
                  }
                },
                {
                  title: "สิทธิ์การเข้าใช้งาน",
                  width: "100%",
                  data: "title"
                }
              ]}
            />
          </div>
          <div>
            <div className="row mt-3">
              <button onClick={() => toggle()} className="btn btn-light ml-auto mr-2">ยกเลิก</button>
              <button disabled={!passwordMatch} type="submit" className="btn btn-primary mr-2">บันทึกข้อมูล</button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default AddAdminModal;