import { FC } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getSrcImage } from "../utils/utils";

interface IModalViewImage {
  src: string;
  open: boolean;
  toggle: () => void;
}

const ModalViewImage: FC<IModalViewImage> = ({ src, open, toggle }) => {
  return (
    <Modal
      size="lg"
      isOpen={open}
      toggle={toggle}
      centered={false}
      style={{ maxWidth: 800 }}
    >
      <div className="modal-title modal-header">
        <h5 className="modal-title">รูปภาพ</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="text-center">
        <img
          src={src}
          alt=""
          style={{ maxWidth: 600 }}
          onError={(e) => {
            e.currentTarget.src = getSrcImage();
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalViewImage;
