import moment from "moment";
import { useEffect, useState } from "react";
import { filterAppealApi } from "../../api/appeal";
import Breadcrumb from "../../component/breadcrumb";
import Datatable from "../../component/datatable";
import Pagination from "../../component/pagination";
import { IAppeal } from "../../interface/appeal";
import Meta from "../../interface/meta";
import { IUserSlim } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import FileSaver from "file-saver";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";


const AppealList = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [appeal, setAppeal] = useState<IAppeal[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    begin: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
  })


  const filterAppeal = async (filter, page: number, limit: number) => {
    try {
      const { data } = await filterAppealApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        page,
        limit,
      });

      setAppeal(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const exportAppeal = async () => {
    try {
      setLoading(true);
      const response = await filterAppealApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        type: "csv",
      });
      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      FileSaver.saveAs(blob, `appeal-${filter.begin}-${filter.end}.csv`);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterAppeal(filter, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"ร้องเรียน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ร้องเรียน" }
        ]}
      />


      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 5 }}>{`${moment().format("DD")} ${moment().format("MMMM")} ${moment().format("YYYY")}`}</h4>
        <div className="col-4-lg my-1 ml-auto mr-3" >
          <div className="input-group input-daterange d-flex align-items-center">
            <input
              type="date"
              className="form-control"
              value={filter.begin}
              placeholder="เริ่มต้น"
              onChange={(e) => {
                setFilter(prev => ({
                  ...prev,
                  begin: e.target.value,
                }))
              }}
            />
            <div className="input-group-addon mx-2">ถึง</div>
            <input
              type="date"
              className="form-control"
              value={filter.end}
              placeholder="สิ้นสุด"
              onChange={(e) => {
                setFilter(prev => ({
                  ...prev,
                  end: e.target.value,
                }))
              }}
            />
            <button onClick={() => exportAppeal()} className="btn btn-primary btn-sm ml-2">Export</button>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-12 mb-2">
                  <h4 className="mt-4 ml-1">ทั้งหมด {meta.totalCount.toLocaleString('en-US')} รายการ</h4>
                </div>
              </div>
              {/*  */}
              <div>
                <Datatable
                  columns={[
                    {
                      title: "วันเวลา",
                      width: 200,
                      data: "createdAt",
                      render(data: string, type, row, meta) {
                        return moment(data).format("DD/MM/yyyy HH:mm:ss")
                      },
                    },
                    {
                      title: "ID ผู้แจ้ง",
                      data: null,
                      width: 200,
                      createdCell(cell, cellData, rowData: IAppeal, rowIndex, colIndex) {
                        createRoot(cell).render(
                          <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/users/edit/${rowData.user.id}`)}>{rowData.user.userNo}</div>
                        )
                      },
                      render(data: IUserSlim, type, row, meta) {
                        if (!data) return ""
                        return `${data.userNo}`
                      },
                    },
                    {
                      title: "ชื่อผู้แจ้ง",
                      data: "user",
                      width: 200,
                      render(data: IUserSlim, type, row, meta) {
                        if (!data) return ""
                        return `${data.firstName} ${data.lastName}`
                      },
                    },
                    {
                      title: "หัวข้อ",
                      width: 220,
                      data: "title"
                    },
                    {
                      title: "รายละเอียด",
                      data: "detail",
                      width: 250,
                      render(data: string, type, row, meta) {
                        return `<div style="width:200px" class="line-clamp-2">${data}</div>`
                      },
                    },
                  ]}
                  data={appeal}
                />

                <Pagination
                  active={meta.page}
                  limit={meta.limit}
                  onPageChange={(page) => {
                    filterAppeal(filter, page, meta.limit);
                  }}
                  pageRangeDisplayed={5}
                  total={meta.totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AppealList;
