import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar'
import _ from 'lodash';
import { filterUsersApi, updateUsersStatusApi } from '../../api/user';
import Datatable, { DatatableElement } from '../../component/datatable';
import Meta from '../../interface/meta';
import { IUser } from '../../interface/user';
import { selectApp, setAppLoading } from '../../redux/app.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createRoot } from 'react-dom/client';
import Pagination from '../../component/pagination';
import { getSrcImage } from '../../utils/utils';
import ModalViewImage from '../../component/modal-view-image';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../component/breadcrumb';

const DriverReviewList = () => {
  const dispatch = useAppDispatch();
  const { badge } = useAppSelector(selectApp)
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const userId = useRef<HTMLInputElement>(null);
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);

  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterUsers = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterUsersApi({
        role: "driver",
        status: "3",
        userNo: userId.current?.value,
        page,
        limit,
      });

      setUsers(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateStatusUsers = async () => {
    try {
      if (action.current?.value === "") return

      const selected: IUser[] = table.current?.selected();
      if (selected.length === 0) return
      setLoading(true);
      const uid = selected.map(e => e.id);
      await updateUsersStatusApi({ uid, status: +action.current!.value });
      filterUsers(1, meta.limit);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const updateStatusUserById = async (id: string, status: number) => {
    try {
      setLoading(true);
      await updateUsersStatusApi({ uid: [id], status });
      filterUsers(meta.page, meta.limit);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterUsers(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  let debounce: any = undefined;

  return (
    <>
      <Breadcrumb
        title={"พนักงานขับรถ"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "พนักงานขับรถ" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <Link className={`nav-link`} to={`${process.env.PUBLIC_URL}/drivers`}>พนักงานทั้งหมด</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link active`} to={`${process.env.PUBLIC_URL}/drivers/review`}>
                สมัครใหม่
                {
                  badge.newDriver > 0 && (
                    <span className="badge badge-pill badge-danger ml-1">{badge.newDriver}</span>
                  )
                }
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-row mb-2">
                <h3 className="mt-4 ml-1">ทั้งหมด {meta.totalCount.toLocaleString('en-US')} รายการ</h3>
                <div className='d-flex flex-row align-items-end' style={{ gap: "1rem" }}>
                  <div>
                    <span className="text-muted tx-13">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control global_filter"
                      ref={userId}
                      placeholder="ID"
                      onChange={() => {
                        if (!!debounce) {
                          debounce.cancel()
                        }
                        debounce = _.debounce(() => {
                          filterUsers(1, meta.limit)
                        }, 500);
                        debounce();
                      }}
                    />
                  </div>

                  <div style={{ width: 150 }}>
                    <span className="text-muted tx-13">ดำเนินการ</span>
                    <select className="form-control" ref={action}>
                      <option value="">เลือก</option>
                      <option value="1">อนุมัติ</option>
                      <option value="0">ไม่อนุมัติ</option>
                    </select>
                  </div>
                  <button onClick={() => {
                    updateStatusUsers();
                  }} type="button" className="btn btn-primary">ดำเนินการ</button>
                </div>
              </div>

              <Datatable
                ref={table}
                data={users}
                columns={[
                  {
                    isCheckbox: true,
                    title: "",
                    data: function (row, type, set) {
                      return '';
                    }
                  },
                  {
                    title: "รูป",
                    width: "80px",
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer img-thumbmail" onClick={() => {
                          cb(getSrcImage(rowData.picture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "ID",
                    width: "100px",
                    data: "userNo",
                  },
                  {
                    title: "ชื่อ-นามสกุล",
                    width: "200px",
                    render: (_1, _2, row: IUser) => {
                      return `${row.firstName} ${row.lastName}`
                    },
                  },
                  {
                    title: "เลขที่บัตรประชาชน",
                    width: 120,
                    data: "identificationNumber"
                  },
                  {
                    title: "วันเกิด",
                    width: 120,
                    data: "birthDate",
                    render(data, type, row, meta) {
                      return `${data === "" ? "" : moment(data).format("yyyy-MM-DD")}`;
                    },
                  },
                  {
                    title: "เบอร์โทร",
                    width: 120,
                    data: "phoneNumber"
                  },
                  {
                    title: "อีเมล",
                    width: 120,
                    data: "email"
                  },
                  {
                    title: "ทะเบียน",
                    width: 120,
                    data: "licensePlate"
                  },
                  {
                    title: "รุ่น",
                    width: 120,
                    data: "vehicleMake"
                  },
                  {
                    title: "สี",
                    width: 120,
                    data: "vehicleColor"
                  },
                  {
                    title: "ใบขับขี่",
                    width: 50,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div style={{ width: 50 }} className="cursor-pointer rounded" onClick={() => {
                          cb(getSrcImage(rowData.drivingPicture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.drivingPicture)} />
                        </div>
                      )
                    },
                  },
                  {
                    title: "บัตรประชาชน",
                    width: 80,
                    data: null,
                    createdCell(cell, cellData, rowData: IUser) {
                      const cb = (src) => {
                        setShowImage({
                          src: src,
                          show: true,
                        })
                      }
                      createRoot(cell).render(
                        <div className="cursor-pointer rounded" onClick={() => {
                          cb(getSrcImage(rowData.identificationPicture))
                        }}>
                          <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.identificationPicture)} />
                        </div>
                      )
                    },
                  },
                ]}
                actions={[
                  {
                    button: {
                      callback(data: IUser) {
                        updateStatusUserById(data.id, 1);
                      },
                      code: <button className="btn btn-outline-primary btn-sm">อนุมัติ</button>
                    },
                  },
                  {
                    button: {
                      callback(data: IUser) {
                        updateStatusUserById(data.id, 2);
                      },
                      code: <button className="btn btn-outline-warning btn-sm">ไม่อนุมัติ</button>
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterUsers(page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage(prev => ({
            ...prev,
            show: false,
          }))
        }}
      />

    </>
  )
}

export default DriverReviewList;
