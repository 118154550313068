import { useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { loginWithPasswordApi } from "../../api/auth";

const SignIn = () => {
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const payload = {
        email,
        password,
      };

      const { data } = await loginWithPasswordApi(payload);

      localStorage.setItem("token_type", data.tokenType);
      localStorage.setItem("access_token", data.accessToken);
      localStorage.setItem("refresh_token", data.refreshToken);
      localStorage.setItem("expires_at", data.expireAt);

      window.location.href = "/";
    } catch (error: any) {
      openSnackbar(error?.response?.data?.message || "Error");
      setIsLoading(false);
    }
  };

  return (
    <div className="content-wrapper d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5 border">
            <div className="brand-logo">
              <img
                src={`https://xangkhamtransport.com/assets/img/logo.svg`}
                alt="logo"
              />
            </div>
            <h4>Hello! let's get started</h4>
            <h6 className="font-weight-light">Sign in to continue.</h6>
            <form
              className="pt-3"
              onSubmit={(e) => {
                e.preventDefault();
                login(email, password);
              }}
            >
              <div className="form-group">
                <input
                  required
                  type="email"
                  className="form-control form-control-lg"
                  autoComplete="off"
                  placeholder="Username"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="password"
                  className="form-control form-control-lg"
                  autoComplete="off"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  SIGN IN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
