import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRule } from '../interface/rule';
import { IUser } from '../interface/user';
import { RootState } from './store';

export interface AuthState {
  user: IUser,
  rules: IRule,
}

const initialState: AuthState = {
  user: {
    id: "",
    userNo: 0,
    firstName: "",
    lastName: "",
    credit: 0,
    status: 1,
    picture: "",
    email: "",
    emailVerified: false,
    phoneNumber: "",
    phoneVerified: false,
    birthDate: "",
    role: "",
    point: 0,
    gender: "",
    employeeFullTime: false,
    vehicleType: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleColor: "",
    plateColor: "",
    licensePlate: "",
    vehicleYear: 0,
    actNumber: "",
    actExpire: "",
    drivingLicense: "",
    drivingPicture: "",
    frontVehiclePicture: "",
    sideVehiclePicture: "",
    vehicleRegistration: "",
    identificationNumber: "",
    identificationPicture: "",
    distance: 0,
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: ""
  },
  rules: {
    dashboard: false,
    userMember: false,
    riderMember: false,
    order: false,
    sending: false,
    saleBilling: false,
    report: false,
    appeal: false,
    review: false,
    deposit: false,
    withdraw: false,
    news: false,
    serviceSetting: false,
    promotionCode: false,
    setting: false,
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setRules: (state, action: PayloadAction<IRule>) => {
      state.rules = action.payload;
    },
  },
});

export const { setProfile, setRules } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
