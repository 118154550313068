import moment from "moment";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate, useOutletContext } from "react-router-dom";
import { filterReviewsApi } from "../../api/review";
import Datatable from "../../component/datatable";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import { IReview } from "../../interface/review";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const ReportSatisfaction = () => {
  const { from, to } = useOutletContext<{ from: number, to: number }>()
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterReviews = async (from: number, to: number, page: number, limit: number) => {
    try {
      const { data } = await filterReviewsApi({
        begin: from,
        end: to,
        page,
        limit,
      });

      setReviews(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    filterReviews(from, to, 1, meta.limit)
    // eslint-disable-next-line
  }, [from, to]);

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-wrap mt-2">
            <div className="mr-4 mt-2">
              <span className="h1 font-primary mr-2">{meta.totalCount.toLocaleString('en-US')}</span><span className="text-muted mb-0 tx-12">รายการ</span>
            </div>
          </div>
          <Datatable
            columns={[
              {
                title: "หมายเลขออเดอร์",
                width: 200,
                data: "orderNo",
                createdCell(cell, cellData, rowData: IReview, rowIndex, colIndex) {
                  createRoot(cell).render(
                    <div className='hover-underline cursor-pointer' onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/${rowData.id}`)}>{rowData.orderNo}</div>
                  )
                },
              },
              {
                title: "วัน/เดือน/ปี",
                width: 200,
                data: "createdAt",
                render(data: string, type, row, meta) {
                  return moment(data).format("DD/MM/yyyy")
                },
              },
              {
                title: "ประเภทรถ",
                data: "service.name",
              },
              {
                title: "คะแนน",
                data: "rate",
                className: "text-right"
              },
            ]}
            data={reviews}
          />

          <Pagination
            active={meta.page}
            limit={meta.limit}
            onPageChange={(page) => {
              filterReviews(from, to, page, meta.limit);
            }}
            pageRangeDisplayed={5}
            total={meta.totalCount}
          />
        </div>
      </div>
    </>
  )
}
export default ReportSatisfaction;
