import { useOutletContext } from "react-router-dom";

const ReportTripsPerCarPerDay = () => {
  const { from, to } = useOutletContext<{ from: number, to: number }>()

  console.log(from, to)

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-wrap mt-2">
            <div className="mr-4 mt-2">
              <span className="h1 font-primary mr-2">30</span><span className="text-muted mb-0 tx-12">รายการ</span>
            </div>
            <div className="col-lg-2 ml-auto p-0">
              <span className="tx-13 text-muted">ประเภทรถที่ให้บริการ</span>
              <div className="form-group">
                <select className="form-control">
                  <option value="AL">ทั้งหมด</option>
                  <option value="WY">รถ Taxi</option>
                  <option value="AM">รถรับจ้างขนาดเล็ก</option>
                  <option value="AM">รถรับจ้างขนาดกลาง</option>
                  <option value="AM">รถรับจ้างขนาดใหญ่</option>
                </select>
              </div>
            </div>

            <div>
              <button className="btn btn-primary d-none d-md-block ml-2 mt-4">Export data</button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th className="">วัน/เดือน/ปี</th>
                  <th className="text-right">จำนวนเที่ยว/คัน/วัน(เที่ยว)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>2/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>3/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>4/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>5/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>6/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>7/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>8/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>9/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>10/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>11/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>12/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>13/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>14/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
                <tr>
                  <td>15/03/2022</td>
                  <td className="text-right">23,094</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportTripsPerCarPerDay;
