import { Link, useLocation } from "react-router-dom";
import { selectApp } from "../redux/app.reducer";
import { selectAuth } from "../redux/authentication.reducer";
import { useAppSelector } from "../redux/hooks";
import Menu from "./menu";

const Sidebar = () => {
  const { pathname } = useLocation()
  const { rules } = useAppSelector(selectAuth)
  const { badge } = useAppSelector(selectApp)
  const isRouteActive = (pathname: string, path: string): string => {
    if (pathname.split("/").length < 2) {
      return "";
    }
    return pathname.split("/")[1] === path ? "active" : "";
  }

  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav ">
        {
          Menu.map((e) => {
            if (!rules[e.id]) {
              return null;
            }
            return (
              <li key={e.link} className={`nav-item ${isRouteActive(pathname, e.link)}`}>
                <Link className="nav-link" to={e.link}>
                  <span className="menu_icon"><img alt="" src={`${process.env.PUBLIC_URL}/${e.icon}`} /></span>
                  <span className="menu-title">
                    {e.title}
                    {
                      !!e.badgeKey && badge[e.badgeKey] > 0 && (
                        <span className="badge badge-pill badge-danger ml-1">{badge[e.badgeKey]}</span>
                      )
                    }
                  </span>
                </Link>
              </li>
            )
          }).filter(e => !!e)
        }
      </ul>
    </nav>
  )
}

export default Sidebar;