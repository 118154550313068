import React, { FC, useEffect } from "react";
import Breadcrumb from "../../component/breadcrumb";
const $ = require('jquery');
$.dropify = require("dropify");

const UserAdd: FC = () => {
  const password = React.useRef<HTMLInputElement>(null);
  const confirmPassword = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    $('.dropify').dropify();
  }, []);

  const handleShowPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      password.current?.setAttribute("type", "text")
      confirmPassword.current?.setAttribute("type", "text")
    } else {
      password.current?.setAttribute("type", "password")
      confirmPassword.current?.setAttribute("type", "password")
    }
  }

  return (
    <>
      <Breadcrumb
        title={"เพิ่มสมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/drivers" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <form className="forms-sample" onSubmit={(e) => {
            e.preventDefault();
          }}>
            <div className="card">
              <div className="card-body">

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">รูป</label>
                  <div className="col-sm-5">
                    <input type="file" className="dropify" data-max-file-size="1M" />

                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">ชื่อ-นามสกุล</label>
                  <div className="col-sm-5">
                    <input type="text" className="form-control ml-auto" placeholder="" /></div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">เบอร์โทร</label>
                  <div className="col-sm-5">
                    <input type="text" className="form-control ml-auto" placeholder="" /></div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">แต้ม</label>
                  <div className="col-sm-5">
                    <input type="text" className="form-control ml-auto" defaultValue={0} /></div>
                </div>

                <div className="form-group row">
                  <label htmlFor="exampleInputPassword2" className="col-sm-2 col-form-label">วันที่</label>
                  <div className="col-sm-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <input type="date" className="form-control" placeholder="วันที่" />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-5">
                    <input type="email" className="form-control ml-auto" placeholder="" /></div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="name">Password</label>
                  </div>
                  <div className="col-lg-5">
                    <input ref={password} className="form-control" autoComplete="off" type="password" />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-2">
                    <label htmlFor="name">Confirm password</label>
                  </div>
                  <div className="col-lg-5">
                    <input ref={confirmPassword} className="form-control" autoComplete="off" type="password" />
                    <div className="form-check mt-4 ml-2" >
                      <label className="form-check-label">
                        <input type="checkbox" className="form-check-input" onChange={(e) => handleShowPassword(e)} />Show Password
                        <i className="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="exampleInputConfirmPassword2" className="col-sm-2 col-form-label">สถานะ</label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input type="checkbox" defaultChecked={true} />
                      <span className="toggle-slider round"></span>
                    </label>

                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row mt-3">
                <button className="btn btn-light ml-auto mr-2">ยกเลิก</button>
                <button type="submit" className="btn btn-primary mr-2">บันทึกข้อมูล</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default UserAdd;
