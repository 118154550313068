import { useEffect, useState } from "react";
import {
  createAdminApi,
  deleteUsersApi,
  filterUsersApi,
  updateAdminApi,
  updatePasswordAdminApi,
} from "../../api/user";
import Datatable from "../../component/datatable";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import { ICreateAdmin, IUpdateAdmin, IUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import AddAdminModal from "./add-admin-modal";
import ChangePasswordAdminModal from "./change-password-admin-modal";
import EditAdminModal from "./edit-admin-modal";

const SettingAdmin = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showEditAdminModal, setShowEditAdminModal] = useState({
    show: false,
    user: {},
  });
  const [showChangePasswordAdminModal, setShowChangePasswordAdminModal] =
    useState({ show: false, user: {} });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterUsers = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterUsersApi({
        role: "admin",
        page,
        limit,
      });

      setUsers(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const addNewAdmin = async (payload: ICreateAdmin) => {
    try {
      setLoading(true);
      await createAdminApi(payload);
      const { data } = await filterUsersApi({
        role: "admin",
        page: meta.page,
        limit: meta.limit,
      });
      setUsers(data.results ?? []);
      setMeta({
        page: data.meta.page,
        limit: data.meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
      setShowAddAdminModal(false);
      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updateAdmin = async (id: string, payload: IUpdateAdmin) => {
    try {
      setLoading(true);
      await updateAdminApi(id, payload);
      const { data } = await filterUsersApi({
        role: "admin",
        page: meta.page,
        limit: meta.limit,
      });
      setUsers(data.results ?? []);
      setMeta({
        page: data.meta.page,
        limit: data.meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
      setShowEditAdminModal({ show: false, user: {} });
      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const updatePasswordAdmin = async (id: string, password: string) => {
    try {
      setLoading(true);
      await updatePasswordAdminApi(id, { password });
      setLoading(false);
      setShowChangePasswordAdminModal({ show: false, user: {} });
      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const deleteUserAdmin = async (id: string) => {
    try {
      setLoading(true);
      await deleteUsersApi({ uid: [id] });
      const { data } = await filterUsersApi({
        role: "admin",
        page: meta.page,
        limit: meta.limit,
      });
      setUsers(data.results ?? []);
      setMeta({
        page: data.meta.page,
        limit: data.meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
      openSnackbar("Successfuly");
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterUsers(1, 10);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <button
                  onClick={() => setShowAddAdminModal(true)}
                  className="btn btn-outline-primary ml-auto mr-2"
                >
                  + เพิ่มผู้ดูแล
                </button>
              </div>
              <div>
                <Datatable
                  data={users}
                  columns={[
                    {
                      title: "ID",
                      width: "100px",
                      data: "userNo",
                    },
                    {
                      title: "ชื่อ-นามสกุล",
                      width: "200px",
                      render: (_1, _2, row: IUser) => {
                        return `${row.firstName} ${row.lastName}`;
                      },
                    },
                    {
                      title: "อีเมล",
                      width: 120,
                      data: "email",
                    },
                    {
                      title: "สถานะ",
                      width: 100,
                      data: "status",
                      render: (data: number) => {
                        switch (data) {
                          case 0:
                            return "<span class='badge badge-warning'>ระงับ</span>";
                          case 1:
                            return "<span class='badge badge-success'>ใช้งาน</span>";
                          case 2:
                            return "<span class='badge badge-danger'>ไม่อนุมติ</span>";
                          case 3:
                            return "<span class='badge badge-primary'>รออนุมติ</span>";
                          default:
                            return "";
                        }
                      },
                    },
                  ]}
                  actions={[
                    {
                      button: {
                        callback(data: IUser) {
                          setShowEditAdminModal({ show: true, user: data });
                        },
                        code: (
                          <button className="btn btn-outline-primary">
                            แก้ไข
                          </button>
                        ),
                      },
                    },
                    {
                      button: {
                        callback(data: IUser) {
                          setShowChangePasswordAdminModal({
                            show: true,
                            user: data,
                          });
                        },
                        code: (
                          <button className="btn btn-outline-warning">
                            เปลี่ยนรหัสผ่าน
                          </button>
                        ),
                      },
                    },
                    {
                      button: {
                        callback(data: IUser) {
                          deleteUserAdmin(data.id);
                        },
                        code: (
                          <button className="btn btn-outline-danger">ลบ</button>
                        ),
                      },
                      confirm: {
                        title: "ยืนยันการทำรายการ",
                        description: "ยืนยันการลบ User no. #{userNo}",
                        textCancel: "ยกเลิก",
                        classBtnCancel: "btn btn-light",
                        textConfirm: "ยืนยัน",
                        classBtnConfirm: "btn btn-danger",
                      },
                    },
                  ]}
                />

                <Pagination
                  active={meta.page}
                  limit={meta.limit}
                  onPageChange={(page) => {
                    filterUsers(page, meta.limit);
                  }}
                  pageRangeDisplayed={5}
                  total={meta.totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddAdminModal
        show={showAddAdminModal}
        toggle={() => setShowAddAdminModal(!showAddAdminModal)}
        onSubmit={addNewAdmin}
      />

      <EditAdminModal
        user={showEditAdminModal.user as IUser}
        show={showEditAdminModal.show}
        toggle={() =>
          setShowEditAdminModal((prev) => ({ user: {}, show: !prev.show }))
        }
        onSubmit={updateAdmin}
      />

      <ChangePasswordAdminModal
        user={showChangePasswordAdminModal.user as IUser}
        show={showChangePasswordAdminModal.show}
        toggle={() =>
          setShowChangePasswordAdminModal((prev) => ({
            user: {},
            show: !prev.show,
          }))
        }
        onSubmit={updatePasswordAdmin}
      />
    </>
  );
};

export default SettingAdmin;
