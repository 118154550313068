import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { filterReviewsApi } from "../../api/review";
import Breadcrumb from "../../component/breadcrumb";
import Datatable from "../../component/datatable";
import Pagination from "../../component/pagination";
import Meta from "../../interface/meta";
import { IReview } from "../../interface/review";
import { IDriver } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";

const ReviewList = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    begin: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
  });

  const filterReviews = async (filter, page: number, limit: number) => {
    try {
      const { data } = await filterReviewsApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        page,
        limit,
      });

      setReviews(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const exportReview = async () => {
    try {
      setLoading(true);
      const response = await filterReviewsApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        type: "csv",
      });
      let BOM = "\uFEFF";
      let csvData = (BOM + response.data) as any;
      let blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      FileSaver.saveAs(blob, `reviews-${filter.begin}-${filter.end}.csv`);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterReviews(filter, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"รีวิว/ให้คะแนน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "รีวิว/ให้คะแนน" },
        ]}
      />

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 5 }}>{`${moment().format(
          "DD"
        )} ${moment().format("MMMM")} ${moment().format("YYYY")}`}</h4>
        <div className="col-4-lg my-1 ml-auto mr-3">
          <div className="input-group input-daterange d-flex align-items-center">
            <input
              type="date"
              className="form-control"
              value={filter.begin}
              placeholder="เริ่มต้น"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  begin: e.target.value,
                }));
              }}
            />
            <div className="input-group-addon mx-2">ถึง</div>
            <input
              type="date"
              className="form-control"
              value={filter.end}
              placeholder="สิ้นสุด"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  end: e.target.value,
                }));
              }}
            />
            <button
              onClick={() => exportReview()}
              className="btn btn-primary btn-sm ml-2"
            >
              Export
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-12 mb-2">
                  <h4 className="mt-4 ml-1">
                    ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                  </h4>
                </div>
              </div>
              <div>
                <Datatable
                  columns={[
                    {
                      title: "วันเวลา",
                      width: 200,
                      data: "createdAt",
                      render(data: string, type, row, meta) {
                        return moment(data).format("DD/MM/yyyy HH:mm:ss");
                      },
                    },
                    {
                      title: "หมายเลขออเดอร์",
                      width: 200,
                      data: "orderNo",
                      createdCell(
                        cell,
                        cellData,
                        rowData: IReview,
                        rowIndex,
                        colIndex
                      ) {
                        createRoot(cell).render(
                          <div
                            className="hover-underline cursor-pointer"
                            onClick={() => {
                              navigate(
                                `${process.env.PUBLIC_URL}/orders/${rowData.orderId}`
                              );
                            }}
                          >
                            {rowData.orderNo}
                          </div>
                        );
                      },
                    },
                    {
                      title: "ผู้ขับ",
                      data: "driver",
                      width: 200,
                      render(data: IDriver, type, row, meta) {
                        if (!data) return "";
                        return `${data.firstName} ${data.lastName}<br />${data.licensePlate}`;
                      },
                    },
                    {
                      title: "คะแนน",
                      width: 200,
                      data: "rate",
                    },
                    {
                      title: "รายละเอียด",
                      data: "detail",
                      width: 200,
                      render(data: string, type, row, meta) {
                        return `<div style="width:200px" class="line-clamp-2">${data}</div>`;
                      },
                    },
                  ]}
                  data={reviews}
                />

                <Pagination
                  active={meta.page}
                  limit={meta.limit}
                  onPageChange={(page) => {
                    filterReviews(filter, page, meta.limit);
                  }}
                  pageRangeDisplayed={5}
                  total={meta.totalCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewList;
