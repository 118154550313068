import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useOutletContext } from "react-router-dom";
import { findDriverByOrderStatusApi, getDriverOnlineApi } from "../../api/user";
import Datatable from "../../component/datatable";
import { ICarService } from "../../interface/car-service";
import { IDriver } from "../../interface/user";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";

const SendingTabOnline = () => {
  const { carServices } = useOutletContext<{ carServices: ICarService[] }>()
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IDriver[]>([]);

  const getDriverOnline = async () => {
    try {
      setLoading(true);
      const { data } = await getDriverOnlineApi();
      setUsers(data ?? [])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    getDriverOnline();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Datatable
        loading={loading}
        data={users}
        columns={[
          {
            title: "รูป",
            width: "50px",
            data: null,
            createdCell(cell, cellData, rowData: IDriver) {
              createRoot(cell).render(
                <div className="img-thumbmail">
                  <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                </div>
              )
            },
          },
          {
            title: "ชื่อ-นามสกุล",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.firstName} ${row.lastName} <br/>เบอร์โทร ${row.phoneNumber}`
            },
          },
          {
            title: "ข้อมูลรถ",
            width: "220px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.vehicleMake} ${row.vehicleModel} ${row.vehicleColor} <br/>${row.licensePlate}`
            },
          },
          {
            title: "ประเภท",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              console.log(carServices)

              return `${carServices.find(e => e.id === row.vehicleType)?.name ?? ""}`
            },
          },
          {
            title: "สถานะ",
            width: 100,
            data: null,
            render: (_1, _2, _3: IDriver) => {
              return `<span class='badge badge-success'>Stand by</span>`
            },
          },
        ]}
      />
    </>
  )
}

const SendingTabTookOrder = () => {
  const { carServices } = useOutletContext<{ carServices: ICarService[] }>()
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IDriver[]>([]);

  const findDriverByOrderStatus = async () => {
    try {
      setLoading(true);
      const { data } = await findDriverByOrderStatusApi("2");
      setUsers(data ?? [])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    findDriverByOrderStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Datatable
        loading={loading}
        data={users}
        columns={[
          {
            title: "รูป",
            width: "50px",
            data: null,
            createdCell(cell, cellData, rowData: IDriver) {
              createRoot(cell).render(
                <div className="img-thumbmail">
                  <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                </div>
              )
            },
          },
          {
            title: "ชื่อ-นามสกุล",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.firstName} ${row.lastName} <br/>เบอร์โทร ${row.phoneNumber}`
            },
          },
          {
            title: "ข้อมูลรถ",
            width: "220px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.vehicleMake} ${row.vehicleModel} ${row.vehicleColor} <br/>${row.licensePlate}`
            },
          },
          {
            title: "ประเภท",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              console.log(carServices)

              return `${carServices.find(e => e.id === row.vehicleType)?.name ?? ""}`
            },
          },
          {
            title: "สถานะ",
            width: 100,
            data: null,
            render: (_1, _2, _3: IDriver) => {
              return `<span class='badge badge-success'>Stand by</span>`
            },
          },
        ]}
      />
    </>
  )
}

const SendingTabTookPassenger = () => {
  const { carServices } = useOutletContext<{ carServices: ICarService[] }>()
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IDriver[]>([]);

  const findDriverByOrderStatus = async () => {
    try {
      setLoading(true);
      const { data } = await findDriverByOrderStatusApi("3");
      setUsers(data ?? [])

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    findDriverByOrderStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Datatable
        loading={loading}
        data={users}
        columns={[
          {
            title: "รูป",
            width: "50px",
            data: null,
            createdCell(cell, cellData, rowData: IDriver) {
              createRoot(cell).render(
                <div className="img-thumbmail">
                  <img className='lazy img-responsive' alt="" src={getSrcImage(rowData.picture)} />
                </div>
              )
            },
          },
          {
            title: "ชื่อ-นามสกุล",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.firstName} ${row.lastName} <br/>เบอร์โทร ${row.phoneNumber}`
            },
          },
          {
            title: "ข้อมูลรถ",
            width: "220px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              return `${row.vehicleMake} ${row.vehicleModel} ${row.vehicleColor} <br/>${row.licensePlate}`
            },
          },
          {
            title: "ประเภท",
            width: "200px",
            data: null,
            render: (_1, _2, row: IDriver) => {
              console.log(carServices)

              return `${carServices.find(e => e.id === row.vehicleType)?.name ?? ""}`
            },
          },
          {
            title: "สถานะ",
            width: 100,
            data: null,
            render: (_1, _2, _3: IDriver) => {
              return `<span class='badge badge-success'>Stand by</span>`
            },
          },
        ]}
      />
    </>
  )
}

export {
  SendingTabOnline,
  SendingTabTookOrder,
  SendingTabTookPassenger,
}