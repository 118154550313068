import moment from "moment";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { filterOrderReportCarPerDayApi, filterOrderReportStatApi, filterOrderReportTripsPerDayApi } from "../../api/order";
import Breadcrumb from "../../component/breadcrumb";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import fileSaver from 'file-saver';
import { filterReviewsApi } from "../../api/review";

const Report = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    begin: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
  })

  const exportHandle = () => {
    try {
      setLoading(true);
      switch (pathname.split("/").length === 3 ? pathname.split("/")[2] : "stats") {
        case "stats":
          exportStat();
          break
        case "car-per-day":
          exportCarPerDay();
          break
        case "trips-per-day":
          exportTripsPerDay();
          break
        case "satisfaction":
          exportReview();
          break
        default:
          return
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const exportStat = async () => {
    try {
      const response = await filterOrderReportStatApi(
        moment(filter.begin).utc().unix(),
        moment(filter.end).utc().unix(),
        "csv"
      );
      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      fileSaver.saveAs(blob, `stat-${filter.begin}-${filter.end}.csv`);
    } catch (e: any) {
      throw e;
    }
  }

  const exportCarPerDay = async () => {
    try {
      const response = await filterOrderReportCarPerDayApi(
        moment(filter.begin).utc().unix(),
        moment(filter.end).utc().unix(),
        "csv"
      );
      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      fileSaver.saveAs(blob, `car-per-day-${filter.begin}-${filter.end}.csv`);
    } catch (e: any) {
      throw e;
    }
  }

  const exportTripsPerDay = async () => {
    try {
      const response = await filterOrderReportTripsPerDayApi(
        moment(filter.begin).utc().unix(),
        moment(filter.end).utc().unix(),
        "csv"
      );
      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      fileSaver.saveAs(blob, `trip-per-day-${filter.begin}-${filter.end}.csv`);
    } catch (e: any) {
      throw e;
    }
  }

  const exportReview = async () => {
    try {
      const response = await filterReviewsApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        type: "csv",
      });
      let BOM = "\uFEFF";
      let csvData = BOM + response.data as any;
      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });

      fileSaver.saveAs(blob, `reviews-${filter.begin}-${filter.end}.csv`);
    } catch (e: any) {
      throw e;
    }
  }
  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"รายงาน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "รายงาน" }
        ]}
      />

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 5 }}>{`${moment().format("DD")} ${moment().format("MMMM")} ${moment().format("YYYY")}`}</h4>
        <div className="col-4-lg my-1 ml-auto mr-3" >
          <div className="input-group input-daterange d-flex align-items-center">
            <input
              type="date"
              className="form-control"
              value={filter.begin}
              placeholder="เริ่มต้น"
              onChange={(e) => {
                setFilter(prev => ({
                  ...prev,
                  begin: e.target.value,
                }))
              }}
            />
            <div className="input-group-addon mx-2">ถึง</div>
            <input
              type="date"
              className="form-control"
              value={filter.end}
              placeholder="สิ้นสุด"
              onChange={(e) => {
                setFilter(prev => ({
                  ...prev,
                  end: e.target.value,
                }))
              }}
            />
            <button onClick={() => exportHandle()} className="btn btn-primary btn-sm ml-2">Export</button>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12 mb-2">
          <select
            className="form-control"
            defaultValue={pathname.split("/").length === 3 ? pathname.split("/")[2] : "stats"}
            onChange={(e) => {
              navigate(`${process.env.PUBLIC_URL}/report/${e.target.value}`, {
                replace: true,
              })
            }}
          >
            <option value="stats">สถิติ</option>
            <option value="car-per-day">จำนวนรถต่อวัน</option>
            <option value="trips-per-day">จำนวนเที่ยวต่อวัน</option>
            {/* <option value="trips-per-car-day">จำนวนเที่ยวต่อคันต่อวัน</option> */}
            {/* <option value="running-time-per-car-day">ระยะเวลาวิ่งต่อคันต่อวัน</option> */}
            <option value="satisfaction">การประเมินความพึงพอใจ</option>
            {/* <option value="accident">อุบัติเหตุ</option> */}
            {/* <option value="mistake">การกระทำผิด</option> */}
          </select>
        </div>
      </div>

      <Outlet context={{
        from: moment(filter.begin).utc().unix(),
        to: moment(filter.end).utc().unix()
      }} />
    </>
  )
}
export default Report;
