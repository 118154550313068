import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Datatable, { DatatableElement } from "../../component/datatable";
import Meta from "../../interface/meta";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { setAppLoading } from "../../redux/app.reducer";
import { confirmWalletByIdApi, filterWalletsApi } from "../../api/wallet";
import { IWallet } from "../../interface/wallet";
import ModalViewImage from "../../component/modal-view-image";
import { createRoot } from "react-dom/client";
import Pagination from "../../component/pagination";
import { moneyDisplay } from "../../utils/money";

const WithdrawRequestList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [loading, setLoading] = useState(false);
  const name = useRef<HTMLInputElement>(null);
  const table = useRef<DatatableElement>(null);
  const action = useRef<HTMLSelectElement>(null);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  let debounce: any;

  const filterWithdrawHistoryList = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterWalletsApi({
        type: "2",
        status: "2,3",
        userName: name.current?.value,
        page,
        limit,
      });

      setWallets(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const confirmWallet = async (
    wallets: IWallet[],
    action: "approve" | "reject"
  ) => {
    try {
      setLoading(true);

      let req: any = [];
      for (const wallet of wallets) {
        req.push(
          confirmWalletByIdApi({
            walletId: wallet.id,
            action,
          })
        );
      }

      await Promise.all(req);

      const { data } = await filterWalletsApi({
        type: "2",
        status: "2,3",
        userName: name.current?.value,
        page: meta.page,
        limit: meta.limit,
      });

      setWallets(data.results ?? []);
      setMeta({
        page: data.meta.page,
        limit: data.meta.limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });

      openSnackbar("Successfuly");
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterWithdrawHistoryList(1, meta.limit);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-row mb-2">
            <h3 className="mt-4 ml-1">
              ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
            </h3>
            <div
              className="d-flex flex-row align-items-end"
              style={{ gap: "1rem" }}
            >
              <div>
                <span className="text-muted tx-13">ค้นหา</span>
                <input
                  type="text"
                  className="form-control global_filter"
                  ref={name}
                  placeholder="ชื่อ"
                  onChange={() => {
                    if (!!debounce) {
                      debounce.cancel();
                    }
                    debounce = _.debounce(() => {
                      filterWithdrawHistoryList(1, meta.limit);
                    }, 500);
                    debounce();
                  }}
                />
              </div>

              <div style={{ width: 150 }}>
                <span className="text-muted tx-13">ดำเนินการ</span>
                <select className="form-control" ref={action}>
                  <option value="">เลือก</option>
                  <option value="approve">ยกเลิก</option>
                  <option value="reject">อนุมัติ</option>
                </select>
              </div>

              <button
                onClick={() => {
                  if (
                    action.current?.value !== "approve" &&
                    action.current?.value !== "reject"
                  )
                    return;
                  const selected: IWallet[] = table.current?.selected();
                  if (selected.length === 0) return;
                  confirmWallet(selected, action.current?.value);
                }}
                type="button"
                className="btn btn-primary"
              >
                ดำเนินการ
              </button>
            </div>
          </div>

          <Datatable
            ref={table}
            data={wallets}
            columns={[
              {
                title: "",
                isCheckbox: true,
                data: function (row, type, set) {
                  return "";
                },
              },
              {
                title: "Transaction No.",
                width: 120,
                data: "code",
              },
              {
                title: "เวลา",
                width: 150,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return row.createdAt !== ""
                    ? moment(row.createdAt).format("DD/MM/yyyy HH:mm:ss")
                    : "";
                },
              },
              {
                title: "ผู้ถอน",
                width: 200,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return `${row.createdUser.firstName} ${row.createdUser.lastName}`;
                },
              },
              {
                title: "รหัสพนักงาน.",
                width: 100,
                data: null,
                createdCell(
                  cell,
                  cellData,
                  rowData: IWallet,
                  rowIndex,
                  colIndex
                ) {
                  createRoot(cell).render(
                    <div
                      className="hover-underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          `${process.env.PUBLIC_URL}/drivers/${rowData.userId}/profile`
                        )
                      }
                    >
                      {rowData.user.userNo}
                    </div>
                  );
                },
              },
              {
                title: "ชื่อ-นามสกุล",
                width: "200px",
                data: null,
                createdCell(
                  cell,
                  cellData,
                  rowData: IWallet,
                  rowIndex,
                  colIndex
                ) {
                  createRoot(cell).render(
                    <div
                      className="hover-underline cursor-pointer"
                      onClick={() =>
                        navigate(
                          `${process.env.PUBLIC_URL}/drivers/${rowData.userId}/profile`
                        )
                      }
                    >
                      {rowData.user.firstName} {rowData.user.lastName}
                    </div>
                  );
                },
              },
              {
                title: "จำนวนเงิน",
                width: 150,
                data: "amount",
                render(data, type, row: IWallet, meta) {
                  return `<span class="text-success">${moneyDisplay(
                    Math.abs(data)
                  )}</span>`;
                },
              },
              {
                title: "เครดิตคงเหลือ",
                width: 150,
                data: null,
                render(data, type, row: IWallet, meta) {
                  return `${moneyDisplay(row.user.credit)}`;
                },
              },
              {
                title: "ธนาคาร",
                width: 150,
                data: "bankAccount.bank.name",
              },
              {
                title: "ชื่อบัญชี",
                width: 150,
                data: "bankAccount.accountName",
              },
              {
                title: "หมายเลขบัญชี",
                width: 150,
                data: "bankAccount.accountNumber",
              },
              {
                title: "สถานะ",
                data: "status",
                width: 100,
                render(data: number, type, row, meta) {
                  switch (data) {
                    case 0:
                      return "ยกเลิก";
                    case 1:
                      return "อนุมัติ";
                    case 2:
                      return "รอดำเนินการ";
                    case 3:
                      return "กำลังตรวจสอบ";
                    default:
                      return "";
                  }
                },
              },
            ]}
            actions={[
              {
                button: {
                  callback(data: IWallet) {
                    confirmWallet([data], "approve");
                  },
                  code: (
                    <button className="btn btn-outline-primary btn-sm">
                      อนุมัติ
                    </button>
                  ),
                },
              },
              {
                button: {
                  callback(data: IWallet) {
                    confirmWallet([data], "reject");
                  },
                  code: (
                    <button className="btn btn-outline-warning btn-sm">
                      ยกเลิก
                    </button>
                  ),
                },
              },
            ]}
          />

          <Pagination
            active={meta.page}
            limit={meta.limit}
            onPageChange={(page) => {
              filterWithdrawHistoryList(page, meta.limit);
            }}
            pageRangeDisplayed={5}
            total={meta.totalCount}
          />
        </div>
      </div>

      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export default WithdrawRequestList;
