import moment from "moment";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getCarServiceByIdApi } from "../../api/car-service";
import { filterOrdersApi } from "../../api/order";
import { filterWalletsApi } from "../../api/wallet";
import Datatable from "../../component/datatable";
import ModalViewImage from "../../component/modal-view-image";
import Pagination from "../../component/pagination";
import { ICarService } from "../../interface/car-service";
import Meta from "../../interface/meta";
import { IFilterOrder } from "../../interface/order";
import { IUser } from "../../interface/user";
import { IWallet } from "../../interface/wallet";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";
import { moneyDisplay } from "../../utils/money";

const DriverTabProfile = () => {
  const navigate = useNavigate();
  const { user } = useOutletContext<{ user: IUser }>();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [carService, setCarService] = useState<ICarService>({} as ICarService);

  const getCarServiceById = async () => {
    try {
      if (user.vehicleType === "") return;
      const { data } = await getCarServiceByIdApi(user.vehicleType);
      setCarService(data);
    } catch (e: any) {
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const getStatus = () => {
    switch (user.status) {
      case 0:
        return "ระงับ";
      case 1:
        return "ใช้งาน";
      case 2:
        return "ไม่อนุมติ";
      case 3:
        return "รออนุมติ";
      default:
        return "";
    }
  };

  useEffect(() => {
    getCarServiceById();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div>รหัสพนักงาน: {user.userNo}</div>
          <div>
            ชื่อ-นามสกุล: {user.firstName} {user.lastName}
          </div>
          <div>เลขที่บัตรประชาชน: {user.identificationNumber}</div>
          <div>ใบขับขี่: {user.drivingLicense}</div>
          <div>เบอร์โทรศัพท์: {user.phoneNumber}</div>
          <div>อีเมล: {user.email}</div>
          <div>
            วัน/เดือน/ปีเกิด:{" "}
            {user.birthDate === ""
              ? ""
              : moment(user.birthDate).format("yyyy-MM-DD")}
          </div>
          <div>เครดิต: {moneyDisplay(user.credit)}</div>
          <div>แต้ม: {user.point}</div>
          <div>วันที่สมัคร: {moment(user.createdAt).format("yyyy-MM-DD")}</div>
        </div>
        <div className="col-12 col-md-6">
          <div>ประเภทรถ: {carService?.name}</div>
          <div>ยี่ห้อ: {user.vehicleMake}</div>
          <div>รุ่น: {user.vehicleModel}</div>
          <div>สี: {user.vehicleColor}</div>
          <div>ทะเบียน: {user.licensePlate}</div>
          <div>สีป้ายทะเบียน: {user.plateColor}</div>
          <div>ปีผลิต: {user.vehicleYear}</div>
          <div>เลขพรบ.: {user.actNumber}</div>
          <div>
            วันหมดอายุพรบ.:{" "}
            {user.actExpire === ""
              ? ""
              : moment(user.actExpire).format("yyyy-MM-DD")}
          </div>
          <div>สถานะ: {getStatus()}</div>
        </div>

        <div className="col-12 mt-2 text-right">
          <button
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/drivers/edit/${user?.id}`);
            }}
            type="button"
            className="btn btn-primary"
          >
            แก้ไข
          </button>
        </div>
      </div>
    </>
  );
};

const DriverTabServiceHistory = () => {
  let navigate = useNavigate();
  const { user } = useOutletContext<{ user: IUser }>();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IFilterOrder[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterHistoryOrders = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterOrdersApi({
        driverId: user.id,
        page,
        limit,
      });

      setOrders(data.results ?? []);

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterHistoryOrders(1, meta.limit);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h4 className="mt-4 ml-1">
        ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
      </h4>
      <Datatable
        loading={loading}
        columns={[
          {
            title: "Order No.",
            width: 80,
            data: "orderNo",
            createdCell(
              cell,
              cellData,
              rowData: IFilterOrder,
              rowIndex,
              colIndex
            ) {
              createRoot(cell).render(
                <div
                  className="hover-underline cursor-pointer"
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/orders/${rowData.id}`)
                  }
                >
                  {rowData.orderNo}
                </div>
              );
            },
          },
          {
            title: "เวลา",
            width: 150,
            data: "createdAt",
            render(data: string, type, row, meta) {
              return moment(data).format("DD/MM/yyyy HH:mm:ss");
            },
          },
          {
            title: "ค่าโดยสาร",
            data: "totalAmount",
            width: 80,
            render(data: number, type, row, meta) {
              return `${data}`;
            },
          },
          {
            title: "ระยะทาง",
            data: "distance",
            width: 80,
            render(data: number, type, row, meta) {
              return `~ ${data.toFixed(2)}km`;
            },
          },
          {
            title: "สถานะ",
            data: "status",
            width: 80,
            render(data: number, type, row, meta) {
              switch (data) {
                case 0:
                  return "ยกเลิก";
                case 1:
                  return "ออเดอร์ใหม่";
                case 2:
                  return "รับงาน";
                case 3:
                  return "รับผู้โดยสาร";
                case 4:
                  return "มอบหมาย";
                case 5:
                  return "ส่งสำเร็จ";
                default:
                  return "";
              }
            },
          },
          {
            title: "ชำระ",
            width: 80,
            data: "paymentMethod",
          },
        ]}
        data={orders}
      />

      <Pagination
        active={meta.page}
        limit={meta.limit}
        onPageChange={(page) => {
          filterHistoryOrders(page, meta.limit);
        }}
        pageRangeDisplayed={5}
        total={meta.totalCount}
      />
    </>
  );
};

const DriverTabDepositHistory = () => {
  const { user } = useOutletContext<{ user: IUser }>();
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterHistoryDeposit = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterWalletsApi({
        userId: user.id,
        type: "1,3",
        page,
        limit,
      });

      setWallets(data.results ?? []);

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterHistoryDeposit(1, meta.limit);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h4 className="mt-4 ml-1">
        ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
      </h4>
      <Datatable
        loading={loading}
        columns={[
          {
            title: "Transaction No.",
            width: 80,
            data: "code",
          },
          {
            title: "เวลา",
            width: 150,
            data: null,
            render(data, type, row: IWallet, meta) {
              return row.createdAt !== ""
                ? moment(row.createdAt).format("DD/MM/yyyy HH:mm:ss")
                : "";
            },
          },
          {
            title: "จำนวนเงิน",
            width: 150,
            data: "amount",
            render(data, type, row: IWallet, meta) {
              return `<span class="text-success">${moneyDisplay(data)}</span>`;
            },
          },
          {
            title: "ช่องทางชำระ",
            width: 150,
            data: "paymentMethod",
          },
          {
            title: "ไฟล์แนบ",
            width: 150,
            data: null,
            createdCell(cell, cellData, rowData: IWallet) {
              const cb = (src) => {
                setShowImage({
                  src: src,
                  show: true,
                });
              };
              createRoot(cell).render(
                <div
                  className="cursor-pointer rounded"
                  onClick={() => {
                    cb(getSrcImage(rowData.picture));
                  }}
                >
                  <img
                    className="lazy img-responsive"
                    alt=""
                    src={getSrcImage(rowData.picture)}
                  />
                </div>
              );
            },
          },
          {
            title: "สถานะ",
            data: "status",
            width: 80,
            render(data: number, type, row, meta) {
              switch (data) {
                case 0:
                  return "ยกเลิก";
                case 1:
                  return "อนุมัติ";
                case 2:
                  return "รอดำเนินการ";
                case 3:
                  return "กำลังตรวจสอบ";
                default:
                  return "";
              }
            },
          },
        ]}
        data={wallets}
      />

      <Pagination
        active={meta.page}
        limit={meta.limit}
        onPageChange={(page) => {
          filterHistoryDeposit(page, meta.limit);
        }}
        pageRangeDisplayed={5}
        total={meta.totalCount}
      />
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

const DriverTabWithdrawHistory = () => {
  const { user } = useOutletContext<{ user: IUser }>();
  const [showImage, setShowImage] = useState({ show: false, src: "" });
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [meta, setMeta] = useState<Meta>({
    page: 1,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const filterHistoryWithdraw = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const { data } = await filterWalletsApi({
        userId: user.id,
        type: "2",
        page,
        limit,
      });

      setWallets(data.results ?? []);

      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterHistoryWithdraw(1, meta.limit);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h4 className="mt-4 ml-1">
        ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
      </h4>
      <Datatable
        loading={loading}
        columns={[
          {
            title: "Order No.",
            width: 80,
            data: "code",
          },
          {
            title: "เวลา",
            width: 150,
            data: null,
            render(data, type, row: IWallet, meta) {
              return row.createdAt !== ""
                ? moment(row.createdAt).format("DD/MM/yyyy HH:mm:ss")
                : "";
            },
          },
          {
            title: "จำนวนเงิน",
            width: 150,
            data: "amount",
            render(data, type, row: IWallet, meta) {
              return `${Math.abs(data)}`;
            },
          },
          {
            title: "ธนาคาร",
            width: 150,
            data: "bankAccount.bank.name",
          },
          {
            title: "ชื่อบัญชี",
            width: 150,
            data: "bankAccount.accountName",
          },
          {
            title: "หมายเลขบัญชี",
            width: 150,
            data: "bankAccount.accountNumber",
          },
          {
            title: "สถานะ",
            data: "status",
            width: 80,
            render(data: number, type, row, meta) {
              switch (data) {
                case 0:
                  return "ยกเลิก";
                case 1:
                  return "อนุมัติ";
                case 2:
                  return "รอดำเนินการ";
                case 3:
                  return "กำลังตรวจสอบ";
                default:
                  return "";
              }
            },
          },
        ]}
        data={wallets}
      />

      <Pagination
        active={meta.page}
        limit={meta.limit}
        onPageChange={(page) => {
          filterHistoryWithdraw(page, meta.limit);
        }}
        pageRangeDisplayed={5}
        total={meta.totalCount}
      />
      <ModalViewImage
        open={showImage.show}
        src={showImage.src}
        toggle={() => {
          setShowImage((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />
    </>
  );
};

export {
  DriverTabServiceHistory,
  DriverTabProfile,
  DriverTabDepositHistory,
  DriverTabWithdrawHistory,
};
