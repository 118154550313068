import { useEffect, useState } from "react";
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { useParams } from "react-router-dom";
import { getPublicNewsByIdApi } from "../../api/news";
import { INews } from "../../interface/news";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { getSrcImage } from "../../utils/utils";

const RenderNews = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<INews>();
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const getNewsById = async () => {
    try {
      setLoading(true);
      const { data } = await getPublicNewsByIdApi(id!);
      const blocks = htmlToDraft(data.description);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(blocks.contentBlocks)
        )
      )
      setNews(data)
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    getNewsById();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="container-fluid" style={{
        background: "#fff",
        height: "100vh",
        paddingTop: 8,
      }}>
        {
          !!news && (
            <>
              {
                news.cover !== "" && (
                  <img
                    src={getSrcImage(news.cover)}
                    style={{
                      maxWidth: "95%",
                      maxHeight: 300,
                      display: "block",
                      margin: "auto",
                    }}
                    alt="cover"
                  />
                )
              }

              <Editor
                editorStyle={{
                  height: "100%",
                }}
                editorState={editorState}
                readOnly={true}
                toolbarHidden={true}
                toolbar={{
                  options: [
                  ],
                }}
              />
            </>
          )
        }

        {
          !loading && !news && (
            <>
              <div style={{ height: "inherit" }} className="align-items-center d-flex justify-content-center text-center">News cannot be loaded right now, sorry.</div>
            </>
          )
        }


      </div>
    </>
  )
}
export default RenderNews;
