import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas, OffcanvasBody } from "reactstrap";
import { logoutApi } from "../api/auth";
import { unsubsctibeFromTopicApi } from "../api/core";
import { selectApp } from "../redux/app.reducer";
import { selectAuth } from "../redux/authentication.reducer";
import { useAppSelector } from "../redux/hooks";
import { getSrcImage } from "../utils/utils";
import Menu from "./menu";

const Topbar = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const { rules, user } = useAppSelector(selectAuth);
  const { badge } = useAppSelector(selectApp);
  let navigate = useNavigate();

  const toggleNavbar = () => setMenuCollapsed(!menuCollapsed);

  const logout = async () => {
    try {
      let fcmToken = await localStorage.getItem("fcm_token");
      if (!!fcmToken) {
        await unsubsctibeFromTopicApi(fcmToken);
      }

      await logoutApi();
    } finally {
      localStorage.removeItem("token_type");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("fcm_token");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  };

  return (
    <>
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="container-fluid">
          <Link className="" to={"/"}>
            <img
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={`https://xangkhamtransport.com/assets/img/logo.svg`}
              alt="logo"
            />
          </Link>

          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul className="navbar-nav mr-lg-2">
              <li className="nav-item nav-search d-none d-lg-block">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="search">
                      <i className="mdi mdi-magnify"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for anything..."
                    aria-label="search"
                    aria-describedby="search"
                  />
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-profile dropdown">
                <button
                  className="nav-link"
                  data-toggle="dropdown"
                  type="button"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <img
                    src={getSrcImage(user.picture)}
                    alt="profile"
                    style={{ width: 40, height: 40 }}
                  />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="profileDropdown"
                >
                  <Link to="profile" className="dropdown-item">
                    <i className="mdi mdi-settings "></i>
                    Settings
                  </Link>
                  <div className="dropdown-item" onClick={() => logout()}>
                    <i className="mdi mdi-logout"></i>
                    Logout
                  </div>
                </div>
              </li>
            </ul>
            <button
              onClick={toggleNavbar}
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
            >
              <span className="mdi mdi-menu"></span>
            </button>
            <Offcanvas isOpen={!menuCollapsed} toggle={toggleNavbar}>
              <div className="mr-4 mt-4">
                <button type="button" onClick={toggleNavbar} className="close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <OffcanvasBody>
                {Menu.map((e) => {
                  if (!rules[e.id]) {
                    return null;
                  }
                  return (
                    <div
                      key={e.link}
                      style={{
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        toggleNavbar();
                        navigate(`${process.env.PUBLIC_URL}/${e.link}`);
                      }}
                    >
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/${e.icon}`}
                        style={{
                          width: 25,
                          height: 25,
                          paddingRight: 8,
                          opacity: 0.5,
                        }}
                      />
                      <span className="menu-title">
                        {e.title}
                        {!!e.badgeKey && badge[e.badgeKey] > 0 && (
                          <span className="badge badge-pill badge-danger ml-1">
                            {badge[e.badgeKey]}
                          </span>
                        )}
                      </span>
                    </div>
                  );
                }).filter((e) => !!e)}
              </OffcanvasBody>
            </Offcanvas>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Topbar;
