import React, { useState, useEffect, useMemo, useRef, FC, memo } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  findDriverByOrderStatusApi,
  getCurrentLocationDriverOnlineApi,
  getDriverOnlineApi,
} from "../../api/user";
import { getSrcImage } from "../../utils/utils";
import moment from "moment";

interface IMarker {
  id: string;
  picture: string;
  lat: number;
  lng: number;
}

const Marker: FC<
  google.maps.MarkerOptions & {
    picture: string;
  }
> = memo(({ icon, title, picture, ...options }) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    if (!marker && options.map) {
      const newMarker = new google.maps.Marker();
      newMarker.setMap(options.map);
      setMarker(newMarker);
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, options.map]);

  useEffect(() => {
    if (marker && picture) {
      const img = new Image();
      img.src = picture;
      img.alt = "";
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const size = 40;
        canvas.width = size;
        canvas.height = size;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.save();
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(img, 0, 0, size, size);
          ctx.restore();
          marker.setOptions({
            ...options,
            icon: {
              url: canvas.toDataURL(),
              scaledSize: new google.maps.Size(size, size),
              anchor: new google.maps.Point(size / 2, size / 2),
            },
          });
        }
      };
    }
  }, [marker, options, picture]);

  return null;
});

Marker.displayName = "Marker";

const SendingMapComponent = () => {
  const [center, setCenter] = useState({ lat: 19.8833, lng: 102.1387 });
  const [filter, setFilter] = useState<{
    licensePlate: string;
    status: string;
  }>({ licensePlate: "", status: "" });
  const mapRef = useRef<google.maps.Map>();
  const { data } = useQuery({
    queryKey: ["getDriverOnlineAndLocation"],
    queryFn: async () => {
      return await Promise.all([
        getDriverOnlineApi(),
        getCurrentLocationDriverOnlineApi(),
      ]);
    },
    refetchInterval: 5000,
  });
  const { data: driverByOrderStatus } = useQuery({
    queryKey: ["getDriverOnlineByOrderStatus", filter.status],
    queryFn: async () => {
      if (filter.status !== "")
        return await findDriverByOrderStatusApi(filter.status);
      return null;
    },
  });
  const markers = useMemo<IMarker[]>(() => {
    if (data && data[1].data) {
      const drivers = data[1].data.reduce((a, b) => {
        const driver = data[0].data.find((e) => e.id === b.id);
        if (driver) {
          if (
            filter.licensePlate !== "" &&
            !driver.licensePlate
              .toUpperCase()
              .includes(filter.licensePlate.toUpperCase())
          )
            return a;
          return {
            ...a,
            [b.id]: {
              id: b.id,
              lat: b.lat,
              lng: b.lng,
              picture: driver.picture,
            },
          };
        }
        return a;
      }, {} as { [key: string]: IMarker });
      let filteredDriver: IMarker[] = Object.values(drivers);
      if (filter.status !== "" && driverByOrderStatus) {
        filteredDriver = driverByOrderStatus.data?.reduce((a, b) => {
          if (drivers[b.id]) return [...a, drivers[b.id]];
          return a;
        }, [] as IMarker[]);
      }
      return filteredDriver;
    }
    return [];
  }, [data, filter, driverByOrderStatus]);

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const onDragEnd = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      if (center) {
        setCenter({
          lat: center.lat(),
          lng: center.lng(),
        });
      }
    }
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-lg">
          <h4 className="mt-2">
            {`${moment().format("DD")} ${moment().format(
              "MMMM"
            )} ${moment().format("YYYY")}`}
          </h4>
        </div>
        <div className="col-lg ml-auto">
          <input
            type="text"
            className="form-control"
            id="car_number_filter"
            placeholder="ค้นหาตามทะเบียนรถ"
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                licensePlate: e.target.value ?? "",
              }))
            }
          />
        </div>
        <div className="col-lg">
          <select
            className="form-control"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, status: e.target.value ?? "" }))
            }
          >
            <option value="">สถานะทั้งหมด</option>
            <option value="2">กำลังดำเนินการ</option>
            <option value="3">กำลังจัดส่ง</option>
          </select>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <LoadScript googleMapsApiKey="AIzaSyCP9EKJM0aXz67kP-FMmpPoTGfa6YDH7p0">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={15}
              center={center}
              options={{
                disableDefaultUI: true, // Hides all default UI controls
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
              onLoad={(map) => {
                mapRef.current = map;
              }}
              onDragEnd={onDragEnd}
            >
              {markers.map((e) => (
                <Marker
                  key={e.id}
                  position={{ lat: e.lat, lng: e.lng }}
                  picture={getSrcImage(e.picture)}
                  map={mapRef.current}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </>
  );
};

export default SendingMapComponent;
