import moment from "moment";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ICarService } from "../../interface/car-service";
import { filterCarServiceApi } from "../../api/car-service";
import useSnackbar from "../../utils/snackbar";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import { countDriverByOnlineAndByOrderStatusApi } from "../../api/user";
import SendingMapComponent from "./SendingMapComponent";

const Sending = () => {
  // const { isLoaded, loadError } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyDOxhEvi4hQO5fCdY7ca6SBMDviysSGhsc",
  // })
  let { pathname } = useLocation();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [currentTab, setCurrentTab] = useState("");
  const [carServices, setCarServices] = useState<ICarService[]>([]);
  const [countDriver, setCountDriver] = useState<any>({});
  // const [currentLoc, setCurrentLoc] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const initData = async () => {
    try {
      setLoading(true);

      await Promise.all([
        filterCarService(),
        countDriverByOnlineAndByOrderStatus(),
        // getCurrentLocationDriverOnline(),
      ]);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterCarService = async () => {
    try {
      const { data } = await filterCarServiceApi({});
      setCarServices(data ?? []);
    } catch (e: any) {
      throw e;
    }
  };

  const countDriverByOnlineAndByOrderStatus = async () => {
    try {
      const { data } = await countDriverByOnlineAndByOrderStatusApi("2,3");
      setCountDriver(data);
    } catch (e: any) {
      throw e;
    }
  };

  // const getCurrentLocationDriverOnline = async () => {
  //   try {
  //     const { data } = await getCurrentLocationDriverOnlineApi();
  //     setCurrentLoc(data ?? [])
  //   } catch (e: any) {
  //     throw e;
  //   }
  // }

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentTab(
      pathname.split("/").length === 3 ? pathname.split("/")[2] : "online"
    );
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"การจัดส่ง"}
        children={[{ title: "หน้าแรก", link: "/" }, { title: "การจัดส่ง" }]}
      />
      <SendingMapComponent />
      <div className="row mb-2">
        <div className="col-12">
          <Nav tabs fill>
            <NavItem>
              <NavLink
                className={currentTab === "online" ? "active" : ""}
                onClick={() => {
                  countDriverByOnlineAndByOrderStatus();
                  navigate(`${process.env.PUBLIC_URL}/sending/online`);
                }}
              >
                <span className="h2 mr-2">{countDriver.online ?? 0}</span>
                <br />
                พนักงานที่ออนไลน์
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentTab === "took-order" ? "active" : ""}
                onClick={() => {
                  countDriverByOnlineAndByOrderStatus();
                  navigate(`${process.env.PUBLIC_URL}/sending/took-order`);
                }}
              >
                <span className="h2 mr-2">{countDriver["2"] ?? 0}</span>
                <br />
                กำลังดำเนินการ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentTab === "took-passenger" ? "active" : ""}
                onClick={() => {
                  countDriverByOnlineAndByOrderStatus();
                  navigate(`${process.env.PUBLIC_URL}/sending/took-passenger`);
                }}
              >
                <span className="h2 mr-2">{countDriver["3"] ?? 0}</span>
                <br />
                กำลังจัดส่ง
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="bg-white">
            <TabPane>
              {!loading && <Outlet context={{ carServices }} />}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};
export default Sending;
