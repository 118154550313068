import { AxiosResponse } from "axios";
import { IAppealWithPagignation } from "../interface/appeal";
import { http } from "../utils/http";

export const filterAppealApi = async (payload: {
  begin: number;
  end: number;
  page?: number;
  limit?: number;
  type?: "csv",
}): Promise<AxiosResponse<IAppealWithPagignation>> => {
  return await http.get("/v1/admin/appeal/filter", payload)
};
