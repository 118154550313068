import axios, { Canceler } from "axios";
import { unsubsctibeFromTopicApi } from "../api/core";
import { HOST } from "../config";

const CancelToken = axios.CancelToken;

const get = async (url = "", params = {}, withAuth = true, cancelToken = (c: Canceler) => { }): Promise<any> => {
  let headers = {
    'Content-Type': 'application/json',
  }
  if (withAuth) {
    try {
      const auth = await authHeader();
      headers = { ...headers, ...auth }
    } catch (e) {
      return e
    }
  }

  return axios({
    url: `${HOST}${url}`,
    method: "get",
    params,
    headers,
    cancelToken: new CancelToken(cancelToken),
  })
}

const post = async (url = "", data: object | FormData | null, withAuth = true, cancelToken = (c: Canceler) => { }): Promise<any> => {
  const typeFormData = (new FormData()).constructor;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    cancelToken: new CancelToken(cancelToken),
  }

  if (data?.constructor === typeFormData) {
    config["headers"] = {
      'Content-Type': 'multipart/form-data',
    }
  }


  if (withAuth) {
    try {
      const auth = await authHeader();
      config.headers = { ...config.headers, ...auth }
    } catch (e) {
      return e
    }
  }
  return axios.post(`${HOST}${url}`, data, config)
}

const put = async (url = "", data = {}, withAuth = true, cancelToken = (c: Canceler) => { }): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    cancelToken: new CancelToken(cancelToken),
  }
  if (withAuth) {
    try {
      const auth = await authHeader();
      config.headers = { ...config.headers, ...auth }
    } catch (e) {
      return e
    }
  }

  return axios.put(`${HOST}${url}`, data, config)
}

const patch = async (url = "", data = {}, withAuth = true, cancelToken = (c: Canceler) => { }): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    cancelToken: new CancelToken(cancelToken),
  }
  if (withAuth) {
    try {
      const auth = await authHeader();
      config.headers = { ...config.headers, ...auth }
    } catch (e) {
      return e
    }
  }

  return axios.patch(`${HOST}${url}`, data, config)
}

const del = async (url = "", withAuth = true, cancelToken = (c: Canceler) => { }): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    cancelToken: new CancelToken(cancelToken),
  }
  if (withAuth) {
    try {
      const auth = await authHeader();
      config.headers = { ...config.headers, ...auth }
    } catch (e) {
      return e
    }
  }

  return axios.delete(`${HOST}${url}`, config)
}

const authHeader = async (): Promise<{ Authorization: string }> => {
  const accessToken = localStorage.getItem('access_token');
  try {
    if (accessToken) {
      const tokenType = localStorage.getItem('token_type');
      const expiresAt = +(localStorage.getItem('expires_at') || 0);
      const now = new Date();
      const currentUnixSeconds = Math.round(now.getTime() / 1000);

      if (currentUnixSeconds >= expiresAt) {
        const refreshToken = localStorage.getItem('refresh_token');
        const payload = {
          accessToken,
          refreshToken,
        }
        const { data } = await post("/v1/auth/token", payload, false)
        localStorage.setItem('token_type', data.tokenType);
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('refresh_token', data.refreshToken);
        localStorage.setItem('expires_at', data.expireAt);
        return { Authorization: `${data.tokenType} ${data.accessToken}` };
      }
      return { Authorization: `${tokenType} ${accessToken}` };
    } else {
      throw Error("Unauthorized")
    }
  } catch (err: any) {
    const { response } = err
    localStorage.removeItem('token_type');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_at');

    if ([401].includes(response?.status)) {
      let fcmToken = await localStorage.getItem("fcm_token");
      if (fcmToken) {
        await unsubsctibeFromTopicApi(fcmToken);
      }
    }
    
    window.location.href = `${process.env.PUBLIC_URL}/login`
    throw err;
  }
}

export const http = {
  get,
  post,
  put,
  patch,
  del,
}
