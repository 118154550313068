import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProfileUserByApi, updateUserProfileByIdApi } from "../../api/user";
import Breadcrumb from "../../component/breadcrumb";
import { IAdminUpdateUser } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { getSrcImage } from "../../utils/utils";
const $ = require('jquery');
$.dropify = require("dropify");

const UserEdit = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [userNo, setUserNo] = useState<number>();
  const [user, setUser] = useState<IAdminUpdateUser>({} as IAdminUpdateUser);
  const [loading, setLoading] = useState(false);

  const initDropify = (defaultFile?: string) => {
    const drEvent = $('.dropify').dropify({
      defaultFile: !!defaultFile ? getSrcImage(defaultFile) : "",
    });

    drEvent.on('dropify.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        picture: "",
      }))
    });
  }

  const getProfileUserById = async () => {
    try {
      setLoading(true);

      const { data } = await getProfileUserByApi(id!);
      setUser(data as IAdminUpdateUser)
      setUserNo(data.userNo)
      initDropify(data.picture)

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/users`)
    }
  }

  const updateUserProfileById = async (payload: IAdminUpdateUser) => {
    try {

      await updateUserProfileByIdApi(id!, payload);
      await getProfileUserById();
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    getProfileUserById()
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"แก้ไขสมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/users" }
        ]}
      />

      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-body">
              <form className="forms-sample" onSubmit={(e) => {
                e.preventDefault();
                updateUserProfileById(user);
              }}>
                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">รูป</label>
                  <div className="col-sm-5">
                    <input
                      type="file"
                      onChange={(e) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(e.target.files![0]);
                        reader.onload = function () {
                          setUser(prev => ({
                            ...prev,
                            picture: reader.result as string,
                          }))
                        };
                      }}
                      className="dropify"
                      data-max-file-size="1M"
                      data-allowed-file-extensions="jpg png jpeg"
                    />

                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">Use No.</label>
                  <div className="col-sm-5">
                    <input type="text" defaultValue={userNo} className="form-control ml-auto" disabled />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">ชื่อ</label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder=""
                      value={user?.firstName}
                      onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          firstName: e.target.value,
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">นามสกุล</label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      placeholder=""
                      value={user?.lastName}
                      onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          lastName: e.target.value,
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">เบอร์โทร</label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control ml-auto"
                      value={user?.phoneNumber}
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-5">
                    <input
                      type="email"
                      className="form-control ml-auto"
                      value={user?.email}
                      onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="" className="col-sm-2 col-form-label">แต้ม</label>
                  <div className="col-sm-5">
                    <input
                      type="number"
                      min={0}
                      step={1}
                      className="form-control ml-auto"
                      value={user?.point}
                      onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          point: +e.target.value,
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="exampleInputPassword2" className="col-sm-2 col-form-label">วันเกิด</label>
                  <div className="col-sm-5">
                    <div className="input-group d-flex align-items-center">
                      <input
                        type="date"
                        className="form-control"
                        value={user?.birthDate !== "" ? moment(user?.birthDate).format("yyyy-MM-DD") : ""}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            birthDate: moment(e.target.value).format("yyyy-MM-DD 00:00:00.000"),
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="exampleInputConfirmPassword2" className="col-sm-2 col-form-label">สถานะ</label>
                  <div className="col-sm-9">
                    <label className="toggle-switch toggle-switch-success">
                      <input type="checkbox" checked={user?.status === 1} onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          status: e.target.checked ? 1 : 0,
                        }))
                      }} />
                      <span className="toggle-slider round"></span>
                    </label>

                  </div>
                </div>
                <div>
                  <div className="row mt-3">
                    <button onClick={() => {
                      navigate(`${process.env.PUBLIC_URL}/users`)
                    }} className="btn btn-light ml-auto mr-2">ยกเลิก</button>
                    <button type="submit" className="btn btn-primary mr-2">บันทึกข้อมูล</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserEdit;
