import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { selectApp } from "../../redux/app.reducer";
import { useAppSelector } from "../../redux/hooks";


const Withdraw = () => {
  const { pathname } = useLocation();
  const { badge } = useAppSelector(selectApp)

  return (
    <>
      <Breadcrumb
        title={"ถอนเงิน"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "ถอนเงิน" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <NavLink end to={`${process.env.PUBLIC_URL}/withdraw`} className={`nav-link`}>
                คำขอใหม่
                {
                  badge.newWithdraw > 0 && (
                    <span className="badge badge-pill badge-danger ml-1">{badge.newWithdraw}</span>
                  )
                }
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`${process.env.PUBLIC_URL}/withdraw/history`} className={`nav-link`}>ประวัติ</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`${process.env.PUBLIC_URL}/withdraw/process`} className={`nav-link`}>ถอนเงิน</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  )
}
export default Withdraw;
