import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbbVJiJmusBB6uH2yXiNmwRyGCXlRSyB4",
  authDomain: "cktrans-c0aad.firebaseapp.com",
  projectId: "cktrans-c0aad",
  storageBucket: "cktrans-c0aad.firebasestorage.app",
  messagingSenderId: "457956797070",
  appId: "1:457956797070:web:fd13d038c4ed3f7ef077df",
  measurementId: "G-VEZCQPDD3Y",
};

const msgKey =
  "BCyjo9ysnB6QkOTMNLKiQG6hu6pks17LqEeuuXVtZFxnjBYykjyS0zIchI_3m4eO8j4dNBtrL_TH6rOJqtavyo4";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
