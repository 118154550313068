import moment from 'moment';
import React, { useState } from 'react'
import { countDriverByOnlineAndByOrderStatusApi, filterUsersApi } from '../../api/user';
import Breadcrumb from '../../component/breadcrumb'
import { useSnackbar } from 'react-simple-snackbar'
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setAppLoading } from '../../redux/app.reducer';
import { getOrderSummaryDashboardApi } from '../../api/order';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    user: 0,
    driver: 0,
    online: 0,
    chart: []
  });

  const initData = async () => {
    try {
      setLoading(true);

      await Promise.all([
        filterUsers(),
        countDriverByOnlineAndByOrderStatus(),
        getOrderSummaryDashboard(),
      ]);

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  const filterUsers = async () => {
    try {
      const [user, driver] = await Promise.all([
        filterUsersApi({ role: "user", page: 1, limit: 1 }),
        filterUsersApi({ role: "driver", page: 1, limit: 1 }),
      ]);

      setState(prev => ({
        ...prev,
        user: user.data.meta.totalCount,
        driver: driver.data.meta.totalCount,
      }))
    } catch (e: any) {
      throw e;
    }
  }

  const countDriverByOnlineAndByOrderStatus = async () => {
    try {
      const { data } = await countDriverByOnlineAndByOrderStatusApi();
      setState(prev => ({
        ...prev,
        online: data.online ?? 0,
      }))
    } catch (e: any) {
      throw e;
    }
  }

  const getOrderSummaryDashboard = async () => {
    try {
      const { data } = await getOrderSummaryDashboardApi();
      setState(prev => ({
        ...prev,
        chart: data ?? [],
      }))
    } catch (e: any) {
      throw e;
    }
  }

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"หน้าหลัก"}
        children={[
          { title: "Home", link: "/" },
          { title: "Dashboard" }
        ]}
      />
      <div className="row mb-2">
        <span className="mt-2">
          <h5 className="font-weight-bold mt ml-2">{`วัน${moment().format("dddd")}ที่ ${moment().format("DD")} ${moment().format("MMMM")} ${moment().add('year').format("YYYY")}`}</h5>
        </span>
      </div>

      <div className="row">
        <div className="col-md-4 col-xl-4 stretch-card grid-margin">
          <div className="card card_Hightlight">
            <div className="card-body">
              <div>
                <h6 className="card-title tx-13  text-muted">สมาชิกทั้งหมด</h6>
                <span className="h2 mr-2 ">{state.user.toLocaleString('en-US')}</span><span>คน</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xl-4 stretch-card grid-margin">
          <div className="card card_Hightlight">
            <div className="card-body">
              <h6 className="card-title tx-13  text-muted">พนักงานทั้งหมด</h6>
              <span className="h2 mr-2 ">{state.driver.toLocaleString('en-US')}</span><span>คน</span>
              <div className="row">

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xl-4 stretch-card grid-margin">
          <div className="card card_Hightlight">
            <div className="card-body">
              <h6 className="card-title tx-13  text-muted">พนักงานออนไลน์</h6>
              <span className="h2 mr-2 ">{state.online.toLocaleString('en-US')}</span><span>คน</span>
              <div className="row">

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card ">
            <div className="card-body">

              <div className="d-flex flex-wrap mb-4 mt-4">
                <div className="mr-4">
                  <h2 className="mb-1 font-weight-medium">
                    {
                      (state.chart.reduce((sum, e: any) => sum + (e.totalAmount as number), 0)).toLocaleString('en-US')
                    }
                  </h2>
                  <p className="text-muted mb-0 tx-12">ยอดขายทั้งหมด</p>
                </div>
                {/* <div className="mr-4">
                  <h2 className="mb-1 font-weight-medium">97,506.000</h2>
                  <p className="text-muted mb-0 tx-12">ค่าธรรมเนียมการใช้ระบบ
                  </p>
                </div> */}
                <div>
                  <h2 className="mb-1 font-weight-medium">
                    {
                      (state.chart.reduce((sum, e: any) => sum + (e.totalOrder as number), 0)).toLocaleString('en-US')
                    }
                  </h2>
                  <p className="text-muted mb-0 tx-12">จำนวนออเดอร์</p>
                </div>
              </div>

              <div id="container" style={{
                width: "100%",
                height: 450
              }}>
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'top' as const,
                      },
                    },
                    scales: {

                    }
                  }}
                  data={{
                    labels: state.chart.map((e: any) => (e.date as string)),
                    datasets: [
                      {
                        label: 'ยอดขายทั้งหมด',
                        data: state.chart.map((e: any) => (e.totalAmount as number)),
                        backgroundColor: '#5b2d51',
                      },
                      {
                        label: 'จำนวนออเดอร์',
                        data: state.chart.map((e: any) => (e.totalOrder as number)),
                        backgroundColor: '#eb637d',
                      },
                    ],
                  }}
                />
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Dashboard;
