import { FC, useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb";
import { getProfileUserByApi, updateUserProfileByIdApi } from "../../api/user";
import { useParams, useNavigate } from "react-router-dom";
import { IAdminUpdateUser } from "../../interface/user";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
import { setAppLoading } from "../../redux/app.reducer";
import { getSrcImage } from "../../utils/utils";
import moment from "moment";
import { moneyDisplay } from "../../utils/money";
import { ICarService } from "../../interface/car-service";
import { filterCarServiceApi } from "../../api/car-service";
const $ = require('jquery');
$.dropify = require("dropify");

interface IDriverEdit {
}

const DriverEdit: FC<IDriverEdit> = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" })
  const [userNo, setUserNo] = useState<number>();
  const [user, setUser] = useState<IAdminUpdateUser>({} as IAdminUpdateUser);
  const [loading, setLoading] = useState(false);
  const [carServices, setCarServices] = useState<ICarService[]>([]);

  const initDropify = (profile?: string, driving?: string, idc?: string, fv?: string, sv?: string, rv?: string) => {
    $('.dropify-profile').dropify({
      defaultFile: !!profile ? getSrcImage(profile) : "",
    }).on('dropify-profile.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        picture: "",
      }))
    });

    $('.dropify-driving').dropify({
      defaultFile: !!driving ? getSrcImage(driving) : "",
    }).on('dropify-driving.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        drivingPicture: "",
      }))
    });

    $('.dropify-idc').dropify({
      defaultFile: !!idc ? getSrcImage(idc) : "",
    }).on('dropify-idc.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        identificationPicture: "",
      }))
    });

    $('.dropify-fv').dropify({
      defaultFile: !!fv ? getSrcImage(fv) : "",
    }).on('dropify-fv.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        frontVehiclePicture: "",
      }))
    });

    $('.dropify-sv').dropify({
      defaultFile: !!sv ? getSrcImage(sv) : "",
    }).on('dropify-sv.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        sideVehiclePicture: "",
      }))
    });

    $('.dropify-rv').dropify({
      defaultFile: !!rv ? getSrcImage(rv) : "",
    }).on('dropify-rv.afterClear', function (event, element) {
      setUser(prev => ({
        ...prev,
        vehicleRegistration: "",
      }))
    });
  }

  const initData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        getProfileUserById(),
        filterCarService(),
      ])
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
      navigate(`${process.env.PUBLIC_URL}/drivers`)
    }
  }

  const filterCarService = async () => {
    try {
      const { data } = await filterCarServiceApi({});
      setCarServices(data ?? [])
    } catch (e: any) {
      throw e;
    }
  }

  const getProfileUserById = async () => {
    try {
      const { data } = await getProfileUserByApi(id!);
      setUser(data as IAdminUpdateUser);
      setUserNo(data.userNo);
      initDropify(data.picture, data.drivingPicture, data.identificationPicture, data.frontVehiclePicture, data.sideVehiclePicture, data.vehicleRegistration);
    } catch (e: any) {
      throw e;
    }
  }

  const updateUserProfileById = async (payload: IAdminUpdateUser) => {
    try {
      await updateUserProfileByIdApi(id!, payload);
      openSnackbar("Successfuly");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof error?.response?.data?.message === "string") {
        msg = error?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  }

  useEffect(() => {
    initData()
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(setAppLoading(loading))
    // eslint-disable-next-line
  }, [loading])

  return (
    <>
      <Breadcrumb
        title={"แก้ไขสมาชิก"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: "สมาชิก", link: "/drivers" }
        ]}
      />

      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-body">
              <form onSubmit={(e) => {
                e.preventDefault();
                updateUserProfileById(user);
              }}>
                <fieldset>
                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">รูป</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              picture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-profile"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">รหัสพนักงาน</label>
                    <div className="col-sm-5">
                      <input type="text" defaultValue={userNo} className="form-control ml-auto" disabled />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ชื่อ</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.firstName}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            firstName: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">นามสกุล</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.lastName}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            lastName: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เลขบัตรประชาชน</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.identificationNumber}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            identificationNumber: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เลขที่ใบขับขี่</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.actNumber}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            actNumber: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เบอร์โทร</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.phoneNumber}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">email</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.email}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">วันเกิด</label>
                    <div className="col-sm-5">
                      <div className="input-group d-flex align-items-center">
                        <input
                          type="date"
                          className="form-control"
                          value={user?.birthDate !== "" ? moment(user?.birthDate).format("yyyy-MM-DD") : ""}
                          onChange={(e) => {
                            setUser(prev => ({
                              ...prev,
                              birthDate: moment(e.target.value).format("yyyy-MM-DD 00:00:00.000"),
                            }))
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เพศ</label>
                    <div className="col-sm-5">
                      <select className="form-control" value={user?.gender} onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          gender: e.target.value,
                        }))
                      }}>
                        <option value={""}>ไม่ระบุ</option>
                        <option value={"ชาย"}>ชาย</option>
                        <option value={"หญิง"}>หญิง</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เครดิต</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={`${moneyDisplay(user?.credit)}`}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">แต้ม</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={`${user?.point}`}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ประเภท</label>
                    <div className="col-sm-5">
                      <select className="form-control" value={`${user?.employeeFullTime}`} onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          employeeFullTime: e.target.value === "true",
                        }))
                      }}>
                        <option value={"true"}>พนักงานประจำ</option>
                        <option value={"false"}>พาร์ทไทม์</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">วันที่สมัคร</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={moment(user?.createdAt).format("DD/MM/yyyy HH:mm")}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row border-bottom">
                    <div className="col-12 mb-2">
                      <span className="font-weight-bold h4">ข้อมูลรถยนต์</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ประเภทรถ</label>
                    <div className="col-sm-5">
                      <select className="form-control" value={user?.vehicleType} onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          vehicleType: e.target.value,
                        }))
                      }}>
                        {
                          carServices?.map(e => (
                            <option key={e.id} value={e.id}>{e.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">สีป้ายทะเบียน</label>
                    <div className="col-sm-5">
                      <select className="form-control" value={user?.plateColor} onChange={(e) => {
                        setUser(prev => ({
                          ...prev,
                          plateColor: e.target.value,
                        }))
                      }}>
                        {
                          [
                            { id: "Yellow", name: "Yellow" },
                            { id: "Black", name: "Black" },
                            { id: "Green", name: "Green" },
                            { id: "Blue", name: "Blue" },
                          ].map(e => (
                            <option key={e.id} value={e.id}>{e.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ยี่ห้อ</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.vehicleMake}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            vehicleMake: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">รุ่น</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.vehicleModel}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            vehicleModel: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">สี</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.vehicleColor}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            vehicleColor: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ทะเบียน</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.licensePlate}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            licensePlate: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ปีที่ผลิต</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={`${user?.vehicleYear}`}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            vehicleYear: +e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">เลข พรบ.</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.actNumber}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            actNumber: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">วันหมดอายุ</label>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control ml-auto"
                        placeholder=""
                        value={user?.actExpire !== "" ? moment(user?.actExpire).format("yyyy-MM-DD") : ""}
                        onChange={(e) => {
                          setUser(prev => ({
                            ...prev,
                            actExpire: moment(e.target.value).format("yyyy-MM-DD 00:00:00.000"),
                          }))
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row border-bottom">
                    <div className="col-12 mb-2">
                      <span className="font-weight-bold h4">แนบเอกสาร</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ใบขับขี่</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              drivingPicture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-driving"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">บัตรประชาชน</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              identificationPicture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-idc"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ถ่ายรูปหน้ารถ (ที่เห็นป้ายทะเบียนรถ)</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              frontVehiclePicture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-fv"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">ถ่ายรูปด้านข้าง</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              sideVehiclePicture: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-sv"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">รูปถ่ายหรือสำเนารายการจดทะเบียนที่มีชื่อเจ้าของรถตรงกับชื่อผู้สมัคร</label>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files![0]);
                          reader.onload = function () {
                            setUser(prev => ({
                              ...prev,
                              vehicleRegistration: reader.result as string,
                            }))
                          };
                        }}
                        className="dropify-rv"
                        data-max-file-size="1M"
                        data-allowed-file-extensions="jpg png jpeg"
                      />
                    </div>
                  </div>
                </fieldset>

                <div className="row">
                  <div className="col-12 border-top ">
                    <div className="row mt-2">
                      <button
                        type="button"
                        className="btn btn-outline-light mr-2 ml-auto mt-2"
                        onClick={() => {
                          navigate(`${process.env.PUBLIC_URL}/drivers/${id}/profile`)
                        }}
                      >ยกเลิก</button>
                      <button type="submit" className="btn btn-primary mr-2 mt-2">บันทึก</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DriverEdit;