import { AxiosResponse } from "axios";
import { http } from "../utils/http";
import { IAdminUpdateUser, ICreateAdmin, IDriver, IDriverLocation, IFilterUserPagination, IUpdateAdmin, IUser } from "../interface/user";

export const fetchProfileUserApi = async () => {
  return await http.get("/v1/users/profile")
};

export const filterUsersApi = async (payload: {
  role?: string;
  status?: string;
  userNo?: string;
  keyword?: string;
  isNewMember?: boolean;
  isOnline?: boolean;
  page: number;
  limit: number;
}) => {
  return await http.get("/v1/admin/users/filter", payload)
};

export const filterDriversApi = async (payload: {
  status?: string;
  userNo?: string;
  keyword?: string;
  isNewMember?: boolean;
  isOnline?: boolean;
  vehicleType?: string;
  lat?: number;
  lng?: number;
  page: number;
  limit: number;
}): Promise<AxiosResponse<IFilterUserPagination>> => {
  return await http.get("/v1/admin/drivers/filter", payload)
};

export const getProfileUserByApi = async (id: string): Promise<AxiosResponse<IUser>> => {
  return await http.get(`/v1/admin/users/${id}`)
};


export const updateUsersStatusApi = async (payload: { uid: string[], status: number }): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/users/status`, payload);
};

export const deleteUsersApi = async (payload: { uid: string[] }): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/admin/users/delete`, payload);
};


export const updateUserProfileByIdApi = async (id: string, payload: IAdminUpdateUser): Promise<AxiosResponse<void>> => {
  return await http.put(`/v1/admin/users/profile/${id}`, payload);
};

export const updateUserProfileByMeApi = async (payload: IAdminUpdateUser): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/users/profile`, payload);
};

export const changePasswordByMeApi = async (payload: { oldPassword: string, newPassword: string }): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/users/change-password`, payload);
};

export const createAdminApi = async (payload: ICreateAdmin): Promise<AxiosResponse<IUser>> => {
  return await http.post(`/v1/admin/users/admin`, payload);
};

export const updateAdminApi = async (id: string, payload: IUpdateAdmin): Promise<AxiosResponse<IUser>> => {
  return await http.put(`/v1/admin/users/admin/${id}`, payload);
};

export const updatePasswordAdminApi = async (id: string, payload: { password: string }): Promise<AxiosResponse<void>> => {
  return await http.patch(`/v1/admin/users/admin/password/${id}`, payload);
};

export const getDriverOnlineApi = async (): Promise<AxiosResponse<IDriver[]>> => {
  return await http.get(`/v1/admin/drivers/online`)
};

export const findDriverByOrderStatusApi = async (status: string): Promise<AxiosResponse<IDriver[]>> => {
  return await http.get(`/v1/admin/drivers/order-status`, {
    status
  })
};

export const countDriverByOnlineAndByOrderStatusApi = async (status?: string): Promise<AxiosResponse<any>> => {
  return await http.get(`/v1/admin/drivers/count-online-and-order-status`, {
    status
  })
};

export const getCurrentLocationDriverOnlineApi = async (): Promise<AxiosResponse<IDriverLocation[]>> => {
  return await http.get(`/v1/admin/drivers/online-current-location`)
};