import { AxiosResponse } from "axios";
import { http } from "../utils/http";
import { IPromitionCodeWithPagignation, IPromotionCode, IPromotionCodeRequest, IPromotionCodeUpdateStatusRequest } from "../interface/promotion-code";

export const filterPromotionCodeApi = async (payload: {
  name?: string;
  status?: string;
  page: number;
  limit: number;
}): Promise<AxiosResponse<IPromitionCodeWithPagignation>> => {
  return await http.get("/v1/admin/promotion-code/filter", payload)
};

export const createPromotionCodeApi = async (payload: IPromotionCodeRequest): Promise<AxiosResponse<IPromotionCode>> => {
  return await http.post("/v1/admin/promotion-code", payload)
};

export const updatePromotionCodeByIdApi = async (id: string, payload: IPromotionCodeRequest): Promise<AxiosResponse<IPromotionCode>> => {
  return await http.put(`/v1/admin/promotion-code/${id}`, payload)
};

export const deletePromotionCodeByIdApi = async (id: string): Promise<AxiosResponse<void>> => {
  return await http.del(`/v1/admin/promotion-code/${id}`)
};

export const updateStatusPromotionCodeApi = async (payload: IPromotionCodeUpdateStatusRequest): Promise<AxiosResponse<void>> => {
  return await http.patch(`/v1/admin/promotion-code/status`, payload)
};

export const getPromotionCodeByIdApi = async (id: string): Promise<AxiosResponse<IPromotionCode>> => {
  return await http.get(`/v1/admin/promotion-code/${id}`)
};