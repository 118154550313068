import { AxiosResponse } from "axios";
import { IReview, IReviewWithPagignation } from "../interface/review";
import { http } from "../utils/http";

export const filterReviewsApi = async (payload: {
  type?: string;
  begin: number;
  end: number;
  page?: number;
  limit?: number;
}): Promise<AxiosResponse<IReviewWithPagignation>> => {
  return await http.get("/v1/admin/reviews/filter", payload)
};

export const getReviewByOrderIdApi = async (orderId: string): Promise<AxiosResponse<IReview | null>> => {
  return await http.get(`/v1/admin/reviews/${orderId}`)
};

