

import { AxiosResponse } from "axios";
import { IBadge } from "../interface/badge";
import { IInformation } from "../interface/information";
import { IRule } from "../interface/rule";
import { http } from "../utils/http";

export const uploadImage = async (payload: FormData): Promise<AxiosResponse<string>> => {
  return await http.post("/upload/image", payload)
};

export const updateInformationApi = async (payload: IInformation): Promise<AxiosResponse<IInformation>> => {
  return await http.put("/v1/admin/information", payload)
};

export const getInformationApi = async (): Promise<AxiosResponse<IInformation>> => {
  return await http.get("/v1/admin/information")
};

export const getRuleByUserIdApi = async (id: string): Promise<AxiosResponse<IRule>> => {
  return await http.get(`/v1/admin/rule/${id}`)
};

export const getBadgeNewDriverApi = async (): Promise<AxiosResponse<IBadge>> => {
  return await http.get(`/v1/admin/badge/new-driver`)
};

export const getBadgeNewOrderApi = async (): Promise<AxiosResponse<IBadge>> => {
  return await http.get(`/v1/admin/badge/new-order`)
};

export const getBadgeNewDepositApi = async (): Promise<AxiosResponse<IBadge>> => {
  return await http.get(`/v1/admin/badge/new-deposit`)
};

export const getBadgeNewWithdrawApi = async (): Promise<AxiosResponse<IBadge>> => {
  return await http.get(`/v1/admin/badge/new-withdraw`)
};

export const subsctibeToTopicApi = async (fcmToken: string): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/auth/subscribe/${fcmToken}`, null);
};

export const unsubsctibeFromTopicApi = async (fcmToken: string): Promise<AxiosResponse<void>> => {
  return await http.post(`/v1/auth/unsubscribe/${fcmToken}`, null, false);
};