import { Outlet, matchPath, useLocation, Link } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";


const CarService = () => {
  const { pathname } = useLocation();
  let match = matchPath(pathname, "/car-service");

  return (
    <>
      <Breadcrumb
        title={!!match ? "ตั้งค่ารถ" : "เพิ่มบริการรถ"}
        children={[
          { title: "หน้าแรก", link: "/" },
          { title: !!match ? "ตั้งค่ารถ" : "เพิ่มบริการรถ" }
        ]}
      />

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <Link className={`nav-link ${!!match ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/car-service`}>บริการรถ</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${!match ? "active" : ""}`} to={`${process.env.PUBLIC_URL}/car-service/add`}>เพิ่มบริการรถ</Link>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </>)
}
export default CarService;
