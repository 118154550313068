import moment from "moment";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { filterOrdersApi, filterOrderSummaryStatusApi } from "../../api/order";
import Breadcrumb from "../../component/breadcrumb";
import Datatable from "../../component/datatable";
import Pagination from "../../component/pagination";
import { ILocation } from "../../interface/location";
import Meta from "../../interface/meta";
import { IFilterOrder, IOrder } from "../../interface/order";
import { IDriver } from "../../interface/user";
import { setAppLoading } from "../../redux/app.reducer";
import { useAppDispatch } from "../../redux/hooks";
import useSnackbar from "../../utils/snackbar";
interface FilterState {
  begin: string;
  end: string;
  orderNo: string;
  licensePlate: string;
  status: string;
  type?: number;
}

const OrderList = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openSnackbar] = useSnackbar({ position: "bottom-left" });
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IFilterOrder[]>([]);
  const [summaryData, setSummaryData] = useState({
    "-1": 0,
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });
  const cardSummary = [
    { status: "-1", title: "ออเดอร์ทั้งหมด" },
    { status: 1, title: "ออเดอร์ใหม่" },
    { status: 2, title: "รับงาน" },
    { status: 3, title: "รับผู้โดยสาร" },
    { status: 4, title: "มอบหมาย" },
    { status: 5, title: "ส่งสำเร็จ" },
    { status: 0, title: "ยกเลิก,ไม่สำเร็จ" },
  ];
  const [meta, setMeta] = useState<Meta>({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });

  const [filter, setFilter] = useState<FilterState>({
    begin: moment().format("yyyy-MM-DD"),
    end: moment().format("yyyy-MM-DD"),
    orderNo: "",
    licensePlate: "",
    status: "",
  });

  const filterOrders = async (
    filter: FilterState,
    page: number,
    limit: number
  ) => {
    try {
      const { data } = await filterOrdersApi({
        begin: moment(filter.begin).utc().unix(),
        end: moment(filter.end).utc().unix(),
        orderNo: !filter.orderNo ? undefined : filter.orderNo,
        licensePlate: !filter.licensePlate ? undefined : filter.licensePlate,
        status: !filter.status ? undefined : filter.status,
        type: filter.type,
        page,
        limit,
      });

      setOrders(data.results ?? []);
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.totalCount,
        hasMore: data.meta.hasMore,
      });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  const filterOrderSummaryStatus = async (begin: string, end: string) => {
    try {
      const { data } = await filterOrderSummaryStatusApi({
        begin: moment(begin).unix(),
        end: moment(end).unix(),
      });
      let all = Object.values(data).reduce((s, e) => e + s, 0);
      setSummaryData((prev) => ({
        ...prev,
        "-1": all,
        ...data,
      }));
    } catch (e: any) {
      let msg: string | undefined;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      openSnackbar(msg || "Error: Something wrong.");
    }
  };

  useEffect(() => {
    filterOrders(filter, 1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    filterOrderSummaryStatus(filter.begin, filter.end);
    // eslint-disable-next-line
  }, [filter.begin, filter.end]);

  useEffect(() => {
    dispatch(setAppLoading(loading));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Breadcrumb
        title={"เรียกรถ"}
        children={[{ title: "หน้าแรก", link: "/" }, { title: "เรียกรถ" }]}
      />

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: 5 }}>{`${moment().format(
          "DD"
        )} ${moment().format("MMMM")} ${moment().format("YYYY")}`}</h4>
        <div className="col-4-lg my-1 ml-auto mr-3">
          <div className="input-group input-daterange d-flex align-items-center">
            <input
              type="date"
              className="form-control"
              value={filter.begin}
              placeholder="เริ่มต้น"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  begin: e.target.value,
                }));
              }}
            />
            <div className="input-group-addon mx-2">ถึง</div>
            <input
              type="date"
              className="form-control"
              value={filter.end}
              placeholder="สิ้นสุด"
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  end: e.target.value,
                }));
              }}
            />
            {/* <button id="search" className="btn btn-primary btn-sm ml-2">Export</button> */}
          </div>
        </div>
      </div>

      <div
        className="mb-2"
        style={{ display: "flex", gap: "0.5rem", overflowX: "scroll" }}
      >
        {cardSummary.map((e) => {
          return (
            <div key={e.status} style={{ minWidth: 200, width: "100%" }}>
              <div className="card">
                <div className="card-body">
                  <h6 className="tx-12 text-muted">{e.title}</h6>
                  <span className="h2 mr-2">{summaryData[e.status]}</span>
                  <span className="tx-12 text-muted">รายการ</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {meta.totalCount.toLocaleString("en-US")} รายการ
                  </h4>
                </div>
                <div className="col-lg">
                  <input
                    value={filter.orderNo}
                    type="text"
                    className="form-control global_filter"
                    placeholder="หมายเลขออเดอร์"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        orderNo: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-lg">
                  <input
                    value={filter.licensePlate}
                    type="text"
                    className="form-control global_filter"
                    placeholder="ทะเบียนรถ"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        licensePlate: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="col-lg">
                  <select
                    className="form-control"
                    value={filter.status}
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        status: e.target.value,
                      }));
                    }}
                    placeholder="สถานะ"
                  >
                    <option value="">ทั้งหมด</option>
                    <option value="1">ออเดอร์ใหม่</option>
                    <option value="2">รับงาน</option>
                    <option value="3">รับผู้โดยสาร</option>
                    <option value="4">มอบหมาย</option>
                    <option value="5">สำเร็จ</option>
                    <option value="0">ยกเลิก,ไม่สำเร็จ</option>
                  </select>
                </div>

                <div className="col-lg">
                  <select
                    className="form-control"
                    value={filter.type}
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        type:
                          e.target.value === ""
                            ? undefined
                            : parseInt(e.target.value, 10),
                      }));
                    }}
                    placeholder="ประเภทออเดอร์"
                  >
                    <option value="">ทั้งหมด</option>
                    <option value="0">ทันที</option>
                    <option value="1">ล่วงหน้า</option>
                  </select>
                </div>

                {/* <div className="col-auto">
                  <Link to="" className="btn btn-primary ml-3">มอบหมายงาน</Link>
                </div> */}
              </div>

              <Datatable
                columns={[
                  {
                    title: "",
                    width: 20,
                    isCheckbox: true,
                  },
                  {
                    title: "Order No.",
                    width: 80,
                    data: "orderNo",
                    createdCell(
                      cell,
                      cellData,
                      rowData: IFilterOrder,
                      rowIndex,
                      colIndex
                    ) {
                      createRoot(cell).render(
                        <div
                          className="hover-underline cursor-pointer"
                          onClick={() =>
                            navigate(
                              `${process.env.PUBLIC_URL}/orders/${rowData.id}`
                            )
                          }
                        >
                          {rowData.orderNo}
                        </div>
                      );
                    },
                  },
                  {
                    title: "ประเภทรถ",
                    width: 150,
                    data: "service.name",
                  },
                  {
                    title: "เวลา",
                    width: 150,
                    data: "createdAt",
                    render(data: string, type, row, meta) {
                      return moment(data).format("DD/MM/yyyy HH:mm:ss");
                    },
                  },
                  {
                    title: "ต้นทาง",
                    data: "startLocation",
                    width: 250,
                    render(data: ILocation, type, row, meta) {
                      return `<div style="width:250px" class="line-clamp-1">${data.address}</div>`;
                    },
                  },
                  {
                    title: "ปลายทาง",
                    data: "endLocation",
                    width: 250,
                    render(data: ILocation, type, row, meta) {
                      return `<div style="width:250px" class="line-clamp-1">${data.address}</div>`;
                    },
                  },
                  {
                    title: "ผู้ขับ",
                    data: "driver",
                    width: 150,
                    render(data: IDriver, type, row, meta) {
                      if (!data) return "";
                      return `${data.firstName} ${data.lastName}<br />${data.phoneNumber}`;
                    },
                  },
                  {
                    title: "ข้อมูลรถ",
                    data: "driver",
                    width: 150,
                    render(data: IDriver, type, row, meta) {
                      if (!data) return "";
                      return `${data.licensePlate}`;
                    },
                  },
                  {
                    title: "ค่าโดยสาร",
                    data: "totalAmount",
                    width: 80,
                    render(data: number, type, row, meta) {
                      return `${data}`;
                    },
                  },
                  {
                    title: "ระยะทาง",
                    data: "distance",
                    width: 80,
                    render(data: number, type, row, meta) {
                      return `~ ${data.toFixed(2)}km`;
                    },
                  },
                  {
                    title: "สถานะ",
                    data: "status",
                    width: 80,
                    render(data: number, type, row, meta) {
                      switch (data) {
                        case 0:
                          return "ยกเลิก";
                        case 1:
                          return "ออเดอร์ใหม่";
                        case 2:
                          return "รับงาน";
                        case 3:
                          return "รับผู้โดยสาร";
                        case 4:
                          return "มอบหมาย";
                        case 5:
                          return "ส่งสำเร็จ";
                        default:
                          return "";
                      }
                    },
                  },
                  {
                    title: "ประเภทออเดอร์",
                    data: "type",
                    width: 100,
                    render(data: number, type, row, meta) {
                      switch (data) {
                        case 0:
                          return "ทันที";
                        case 1:
                          return "ล่วงหน้า";
                        default:
                          return "";
                      }
                    },
                  },
                  {
                    title: "ชำระ",
                    width: 80,
                    data: "paymentMethod",
                  },
                ]}
                data={orders}
                actions={[
                  {
                    button: {
                      callback(data: IOrder) {
                        navigate(`${process.env.PUBLIC_URL}/orders/${data.id}`);
                      },
                      code: (
                        <button className="btn btn-outline-primary">
                          รายละเอียด
                        </button>
                      ),
                    },
                  },
                ]}
              />

              <Pagination
                active={meta.page}
                limit={meta.limit}
                onPageChange={(page) => {
                  filterOrders(filter, page, meta.limit);
                }}
                pageRangeDisplayed={5}
                total={meta.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderList;
