import { IMAGE_HOST } from "../config";

export const getSrcImage = (src?: string): string => {
  if (!src)
    return `${process.env.PUBLIC_URL}/images/no-img.jpeg`

  if (src.startsWith("http://") || src.startsWith("https://"))
    return src;

  if (src.startsWith("images"))
    return `${process.env.PUBLIC_URL}/${src}`

  return `${IMAGE_HOST}/${src}`
}