import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface IConfirm {
  title: string;
  description: string;
  textCancel: string;
  textConfirm: string;
  classBtnConfirm: string;
  classBtnCancel: string;
}
export interface IShow {
  show: boolean
}

export interface IIConfirm extends IShow {
  title: string;
  description: string;
  textCancel: string;
  textConfirm: string;
  classBtnConfirm: string;
  classBtnCancel: string;
}

export interface AppState {
  confirm: IIConfirm;
  loading: boolean;
  badge: {
    newOrder: number;
    newDriver: number;
    newDeposit: number;
    newWithdraw: number;
  }
}

const initialState: AppState = {
  confirm: {
    show: false,
    title: "",
    description: "",
    textCancel: "",
    textConfirm: "",
    classBtnConfirm: "",
    classBtnCancel: "",
  },
  badge: {
    newOrder: 0,
    newDriver: 0,
    newDeposit: 0,
    newWithdraw: 0,
  },
  loading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowAppConfirm: (state, action: PayloadAction<IIConfirm>) => {
      state.confirm = action.payload;
    },
    setHideAppConfirm: (state) => {
      state.confirm = initialState.confirm;
    },
    setBadge: (state, action: PayloadAction<{
      type: "newOrder" | "newDriver" | "newDeposit" | "newWithdraw",
      total: number;
    }>) => {
      state.badge[action.payload.type] = action.payload.total;
    }
  },
});

export const { setAppLoading, setShowAppConfirm, setHideAppConfirm, setBadge } = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
